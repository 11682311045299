<template lang="html">
    <div id="uploadModal" :animated="(wizard) ? 'false' : 'true'" class="bg-white px-5 d-flex flex-column justify-content-center align-items-start align-content-start flex-nowrap">

        <div class="w-100 headModal d-flex flex-row mt-4 align-items-center">
            <div class="flex-fill d-flex flex-nowrap align-items-center">
            </div>
            <div class="iconContainer close py-3 px-3" @click="closeModal()"><img v-svg-inline :src="a5"></div>
        </div>

        <div class="col-12 mainModal d-flex flex-fill justify-content-center mt-1 px-2 align-items-start align-content-start">

            <div class="col-6 h-100 d-flex flex-wrap justify-content-center align-items-start align-content-start">
                <div class="col-lg-10 h-100 uploadSide d-flex flex-column flex-nowrap justify-content-center align-items-start align-content-start">

                    <div class="col-12 text-s-1 d-flex justify-content-center mb-5 fw-bold">
                        Arrastra tus archivos
                    </div>
                    <div class="col-12 text-s-1 d-flex flex-fill justify-content-center dragFiles">
                        <div class="w-100 backgrounded" ref="backgroundedDrag">
                            <input id="fileDrag" ref="fileDrag" class="fileDrag" type="file" name="files[]" accept="image/png, image/jpg, image/jpeg, application/pdf" @change="chargeFile($event);" @dragenter="setStyle(1)" @dragleave="setStyle(2)" @dragend="setStyle(2)" @drop="setStyle(3)">
                            <img v-svg-inline :src="a22">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 h-100 d-flex flex-wrap justify-content-end align-items-start align-content-start">
                <div class="files h-100 col-lg-11 d-flex flex-wrap justify-content-end pe-3 align-items-start align-content-start pt-5">

                    <template v-for="(pendFile, pidx) in filesPending">
                        <div class="fileLine w-100 px-2 py-1 d-flex flex-nowrap align-items-center align-content-center">

                            <div class="col-lg-2 h-100 dflex justify-content-center iconContainer">
                                <img :src="pendFile.preview" class="image" v-if="pendFile.type == 'image'">
                                <img v-svg-inline class="other" :src="pendFile.preview" v-else>
                            </div>

                            <div class="col-lg-9 d-flex justify-content-start align-items-center align-content-center h-100 overflow-hidden">

                            <div class="fileProcessLine h-50" v-bind:style="{ width:pendFile.process+'%' }" :class="pendFile.process == 100 ? 'completed' : '' "></div>

                                <div class="fileName d-flex overflow-hidden justify-content-start align-items-center align-content-center py-2" :class="pendFile.process == 100 ? 'completed' : '' ">{{pendFile.name}}</div>
                            </div>

                            <div class="col-lg-1 h-50 hover1">
                                <div @click="removeFile(pidx)" class="iconContainer" v-if="pendFile.process == 100">
                                    <img v-svg-inline :src="a14">
                                </div>
                            </div>

                            
                            
                        </div>
                    </template>
                </div>


            </div>
        </div>

        <div class="w-100 footModal d-flex flex-wrap justify-content-center align-items-start align-content-start py-3">

            <div class="col-6 d-flex justify-content-center align-items-start align-content-start h-100">
                <div class="w-100 mt-5 d-flex justify-content-center">
                    <button class="addFiles px-5 py-2" @click="addFiles()">
                    Adjuntar archivos
                    </button>
                </div>
            </div>

            <div class="col-6 d-flex justify-content-center align-items-start align-content-start h-100">
                <template v-if="checkAllCharge() == true">
                    <button class="nextButton col-6 px-5 mt-5 " @click="next()">Continuar</button>
                </template>
            </div>
                    
        </div>

        </div>
    </div>
</template>
<script>

import axios from "axios";

export default {
    name:"uploadModal",
    emits:['close', 'saveTemp', 'newFile', 'removeFile'],
    props:{
        wizard:false,
        preData:{},
        con:{},
        folders:{},
        patient:0,
        doctor:0,
        auth:''
    },
    data(){
        return{
            a22:require('@/assets/images/expedient/a22.svg'),
            a5:require('@/assets/images/expedient/a5.svg'),
            a10:require('@/assets/images/expedient/a10.svg'),
            a14:require('@/assets/images/expedient/a14.svg'),
            filesPending:[],
            formats:['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
        }
    },
    methods:{
        next(){
            if(this.folders.hasOwnProperty(this.preData.date)){
                if(this.folders[this.preData.date].hasOwnProperty(this.preData.folder)){
                    if(this.folders[this.preData.date][this.preData.folder].hasOwnProperty('shared')){
                        this.$emit('saveTemp', {files:this.filesPending, shared:this.folders[this.preData.date][this.preData.folder].shared}, true, 'share', 'upload');
                        return true;
                    }
                }
            }
            this.$emit('saveTemp', {files:this.filesPending, shared:{patient:false,doctors:[]}}, this.wizard, 'share', 'upload');
        },
        closeModal(){
            this.$emit('closeModal', 'upload');
        },
        checkAllCharge(){
            let sum = 0
            for(let i=0; i<this.filesPending.length;i++){
                sum+=this.filesPending[i].process;
            }
            return (sum/this.filesPending.length) == 100;
        },
        removeFile(index){

            this.con.deleteFile({token: this.auth, id:this.filesPending[index].idAttachment}).then((response) => {
				if(response.code == 200){
                    this.$emit('removeFile', this.filesPending[index]);
                    this.filesPending.splice(index, 1); 
                }
			}).catch((error) => {
				console.log(error)
			});

            /*axios.delete(this.con.api + "apiOnico/index.php/File/"+this.filesPending[index].idAttachment, this.con.headers).then((response) => {
                if(response.status == 200){
                    this.$emit('removeFile', this.filesPending[index]);
                    this.filesPending.splice(index, 1); 
                }
			}).catch((error) => {
				console.log(error)
			})*/
        },
        setStyle(style){
            switch (style){
                case 1:
                    this.$refs.backgroundedDrag.classList.add('dragOn')
                    this.$refs.backgroundedDrag.classList.remove('droping')
                    break;
                case 2:
                    this.$refs.backgroundedDrag.classList.remove('dragOn')
                    this.$refs.backgroundedDrag.classList.remove('droping')
                    break;
                case 3:
                    this.$refs.backgroundedDrag.classList.remove('dragOn')
                    this.$refs.backgroundedDrag.classList.add('droping')
                    break;
                default:
                    break;
            }
        },
        extention(file) {
            return file['type'].split('/')[0];
        },
        addFiles(){
            this.$refs.fileDrag.click();
        },

        uploadOneFile(file){
            const formData = new FormData();
            formData.append('files[]', file);
            formData.append('idPatient', this.patient);
            formData.append('idDoctor', this.doctor);
            formData.append('idAppointment', '0');
            if(this.preData.hasOwnProperty('date')){
                formData.append('date', String(this.preData.date));
            }else{
                return false
            }
            formData.append('folder', this.preData.folder);
            let data = {
                'type':'',
                'name':file.name,
                'preview':null,
                'process':0,
                'date': null,
                'folder': null,
                'idAttachment':null,
            }

            switch(this.extention(file)){
                case 'image':
                    data.type = 'image';
                    data.preview = URL.createObjectURL(file)
                    break;
                default:
                    data.type = 'other';
                    data.preview = this.a10;
                    break;
            }


            let pointer = this.filesPending.length;
            this.filesPending.push(data)

            const config = {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total)*100);
                    this.filesPending[pointer].process = percentCompleted;
                },
                "headers": {
                    "Authorization" : 'Bearer '+this.auth
                }
            }

            axios.post(this.con.apiUrl+'apiOnico/File', formData, config)
            .then((res) => {
                if(res.data != null && res.status == 200){
                   this.filesPending[pointer].date = Object.keys(res.data.data)[0];
                   this.filesPending[pointer].idAttachment = res.data.data[Object.keys(res.data.data)[0]].files[0].idAttachment;
                   this.filesPending[pointer].folder = res.data.data[Object.keys(res.data.data)[0]].files[0].folder;
                   this.$emit('newFile', res.data.data)
                }
            })
            .catch((err) => {console.log(err)})

        },

        chargeFile(e){
            e.preventDefault();
            const input = this.$refs.fileDrag;
            for(let i=0; i<input.files.length; i++){
                if(this.formats.includes(input.files[i].type)){
                    this.uploadOneFile(input.files[i]);
                }
            } 
        }
    }
}
</script>
<style lang="css">
    #uploadModal{
        position: fixed;
        left: 5vw;
        top: 5vh;
        width: 90vw;
        height: 90vh;
        z-index: 2000;
        overflow-y: auto;
        filter: drop-shadow(0px 12px 23px rgba(0,0,0,0.16 ));
        border-radius: 44px;
    }
    #uploadModal[animated=true]{
        animation: appendUp .15s linear 1 forwards;
    }
    .headModal{
        height: 70px;
    }
    .mainModal{
        height: calc(70% - 70px);
    }
    .iconContainer.close{
        cursor: pointer;
    }
    .iconContainer.close:hover{
        opacity: 0.8;
    }
    .iconContainer{
        display: flex;
        align-content: center;
        align-items: center;
        width: 90px;
        height: 100%;
    }
    .iconContainer svg{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .headModal .iconContainer svg path{
        fill:#8B8B8B !important;
    }
    .text-s-1{
        color:#8B8B8B;
        font-size:51px;
    }
    .text-s-2{
        color:#95989A;
        font-size:24px;
    }
    .uploadSide{
        overflow-x: hidden;
        overflow-y: auto;
    }
    .backgrounded{
        width:100%;
        position: relative;
        border-radius: 10px;
        border: 4px dashed #D5D5D5;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        overflow: hidden;
    }
    .backgrounded.droping{
        animation: 1.5s drop linear 1 forwards;
    }
    .backgrounded.droping{
        border: 4px dashed #F8AAA8;
    }
    .backgrounded.droping svg{
        animation: 1.5s dropScale linear 1 forwards;
    }
    .backgrounded.dragOn{
        border: 4px dashed #63F3FF;
    }
    .backgrounded.dragOn svg path{
         fill: #63F3FF;
    }
    .backgrounded svg{
        pointer-events: none;
        position: absolute;
        width: 30%;
        height: 30%;
        border-radius: 10px;
    }
    .fileDrag{
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    .addFiles{
        border-radius: 15px;
        border: 3px solid #F15753;
        color:#F15753;
        background-color: transparent;
        font-weight: bold;
        outline: none;
        font-size: 35px;
    }
    .addFiles:hover{
        border: 3px solid transparent;
        color:#fff;
        background-color: #F15753;
    }
    .files{
        overflow-x: hidden !important;
        overflow-y: auto;
    }
    .fileLine{
        height: 80px;
    }
    .fileLine .other path{
        fill:#8B8B8B;
    }
    .fileLine .image{
        height: 100%;
        width: 100%;
        padding: 10px;
        object-fit: contain;
    }
    .fileProcessLine{
        background-color: #F8AAA8;
        transition: width 1.2s;
    }
    .hover1{
        cursor: pointer;
    }
    .hover1:hover{
        transform-origin: 75% 50%;
        transform: scale(1.05);
        opacity: 0.8;
    }
    .hover1:hover svg path{
        fill: #F8AAA8;
    }
    .fileProcessLine.completed{
        animation: 1s hide linear 1 forwards;
    }
    .fileName{
        width: 0
    }
    .fileName.completed{
        animation: 1s show linear 1s 1 forwards;
    }
    .footModal{
        height: 30%;
    }
    .nextButton{
        border-radius: 19px;
        height: 70px;
        font-weight: bold;
        background-color:#F15753;
        color: #fff;
        font-size: 35px;
        border: 0;
    }
    .nextButton:hover{
        opacity: 0.8;
        color: #D5D5D5;
    }
    @keyframes hide {
        0%{
            opacity: 1;
        }
        99%{
            opacity: 0;
            width: 100%;
        }
        100%{
            opacity: 0;
            width: 0%;
        }
    }
    @keyframes show {
        0%{
            opacity: 0;
        }
        49%{
            width: 0%;
        }
        50%{
            width: 100%;
        }
        100%{
            opacity: 1;
            width: 100%;
        }
    }
    @keyframes drop {
        0%{
            box-shadow: rgb(248, 170, 168, 0.4) 0px 5px inset, rgb(248, 170, 168, 0.3) 0px 10px inset, rgba(240, 46, 170, 0.2) 0px 15px inset, rgb(248, 170, 168, 0.1) 0px 20px inset, rgb(248, 170, 168, 0.05) 0px 25px inset, rgb(248, 170, 168, 0.4) 0px -5px inset, rgb(248, 170, 168, 0.3) 0px -10px inset, rgba(240, 46, 170, 0.2) 0px -15px inset, rgb(248, 170, 168, 0.1) 0px -20px inset, rgb(248, 170, 168, 0.05) 0px -25px inset;
        }
        18%{
           box-shadow: rgb(248, 170, 168, 0.9) 0px 15px inset, rgb(248, 170, 168, 0.3) 0px 20px inset, rgba(240, 46, 170, 0.2) 0px 25px inset, rgb(248, 170, 168, 0.1) 0px 30px inset, rgb(248, 170, 168, 0.05) 0px 35px inset, rgb(248, 170, 168, 0.9) 0px -15px inset, rgb(248, 170, 168, 0.3) 0px -20px inset, rgba(240, 46, 170, 0.2) 0px -25px inset, rgb(248, 170, 168, 0.1) 0px -30px inset, rgb(248, 170, 168, 0.05) 0px -35px inset;
        }
        36%{
            box-shadow: rgb(248, 170, 168, 0.4) 0px 5px inset, rgb(248, 170, 168, 0.3) 0px 10px inset, rgba(240, 46, 170, 0.2) 0px 15px inset, rgb(248, 170, 168, 0.1) 0px 20px inset, rgb(248, 170, 168, 0.05) 0px 25px inset, rgb(248, 170, 168, 0.4) 0px -5px inset, rgb(248, 170, 168, 0.3) 0px -10px inset, rgba(240, 46, 170, 0.2) 0px -15px inset, rgb(248, 170, 168, 0.1) 0px -20px inset, rgb(248, 170, 168, 0.05) 0px -25px inset;
        }
        54%{
           box-shadow: rgb(248, 170, 168, 0.9) 0px 15px inset, rgb(248, 170, 168, 0.3) 0px 20px inset, rgba(240, 46, 170, 0.2) 0px 25px inset, rgb(248, 170, 168, 0.1) 0px 30px inset, rgb(248, 170, 168, 0.05) 0px 35px inset, rgb(248, 170, 168, 0.9) 0px -15px inset, rgb(248, 170, 168, 0.3) 0px -20px inset, rgba(240, 46, 170, 0.2) 0px -25px inset, rgb(248, 170, 168, 0.1) 0px -30px inset, rgb(248, 170, 168, 0.05) 0px -35px inset;
        }
        72%{
            box-shadow: rgb(248, 170, 168, 0.4) 0px 5px inset, rgb(248, 170, 168, 0.3) 0px 10px inset, rgba(240, 46, 170, 0.2) 0px 15px inset, rgb(248, 170, 168, 0.1) 0px 20px inset, rgb(248, 170, 168, 0.05) 0px 25px inset, rgb(248, 170, 168, 0.4) 0px -5px inset, rgb(248, 170, 168, 0.3) 0px -10px inset, rgba(240, 46, 170, 0.2) 0px -15px inset, rgb(248, 170, 168, 0.1) 0px -20px inset, rgb(248, 170, 168, 0.05) 0px -25px inset;
        }
        90%{
           box-shadow: rgb(248, 170, 168, 0.9) 0px 15px inset, rgb(248, 170, 168, 0.3) 0px 20px inset, rgba(240, 46, 170, 0.2) 0px 25px inset, rgb(248, 170, 168, 0.1) 0px 30px inset, rgb(248, 170, 168, 0.05) 0px 35px inset, rgb(248, 170, 168, 0.9) 0px -15px inset, rgb(248, 170, 168, 0.3) 0px -20px inset, rgba(240, 46, 170, 0.2) 0px -25px inset, rgb(248, 170, 168, 0.1) 0px -30px inset, rgb(248, 170, 168, 0.05) 0px -35px inset;
        }
        100%{
            box-shadow: rgb(248, 170, 168, 0.4) 0px 0px inset, rgb(248, 170, 168, 0.3) 0px 0px inset, rgba(240, 46, 170, 0.2) 0px 0px inset, rgb(248, 170, 168, 0.1) 0px 0px inset, rgb(248, 170, 168, 0.05) 0px 0px inset, rgb(248, 170, 168, 0.4) 0px 0px inset, rgb(248, 170, 168, 0.3) 0px 0px inset, rgba(240, 46, 170, 0.2) 0px 0px inset, rgb(248, 170, 168, 0.1) 0px 0px inset, rgb(248, 170, 168, 0.05) 0px 0px inset;
        }
    }
    @keyframes dropScale {
        0%{
            transform: scale(1);
        }
        10%{
            transform: scale(1.2);
        }
        20%{
            transform: scale(0);
        }
        90%{
            transform: scale(0);
        }
        100%{
            transform: scale(1);
        }
    }
</style>