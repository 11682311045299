<template lang="html">
    <div id="lineMenu" class="d-lg-flex d-none align-items-end align-content-end justify-content-center pb-5">
    </div>
</template>
<script>
export default {
    name:"",
    data(){
        return{}
    }
}
</script>
<style lang="css">
    #lineMenu{
        height:100vh;
        width: 200px;
        background-color:#fff;
        border-right: 4px dotted rgba(200, 200, 200, 0.2);
    }
</style>