import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import './assets/css/style.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "vue-svg-inline-plugin/src/polyfills";
import '@/assets/scss/globalColors.scss'

Vue.config.productionTip = false
Vue.use(VueSvgInlinePlugin);

VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
