<template lang="html">
    <div ref="commonModal" class="commonModal modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg px-md-0 px-5">
            <div class="modal-content d-flex justify-content-center align-items-center align-content-center" v-if="showing">
                <div class="closeButton" @click="tryClose()"><img v-svg-inline :src="a29"></div>
                <div class="modal-body w-100 d-flex flex-column justify-content-start align-items-center align-content-center">
                    <div class="w-75 nhb text-start d-flex justify-content-start mb-3 mt-5 titled d-flex">
                        {{ title }}
                    </div>
                    <div class="w-75 nhr contented font-9 px-md-5" v-html="mess">
                    </div>

                    <div class="w-75 d-flex flex-wrap align-items-end align-content-end justify-content-center mb-5" style='flex-grow: 1'>
                        <template v-if="buttons.length > 0">
                            <template v-for="button in buttons">
                                <button class="buttonAction mt-5 pushable nhb px-4 py-1 mx-2" :class="button.type == 'red' ? 'active' : '' " @click="makeAction(button.action)">{{button.title}}</button>
                            </template>
                        </template>
                        <template v-else>
                            <button class="buttonAction mt-5 pushable active nhb px-4 py-1" @click="hideModal()">{{phrases.f1[lang]}}</button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import { Modal } from "bootstrap";

export default {
    emits:['close', 'action'],
    name:"commonModal",
    props:{
        lang:{
            default:"es",
            type: String
        },
        active:{
            default:false,
            type:Boolean
        },
        showIncrement:{
            default:0,
            type:Number
        },
        title:{
            default:'',
            type:String
        },
        mess:{
            default:'',
            type:String
        },
        buttons:{
            default:[],
            type:Array
        },
    },
    watch:{
        showIncrement(){
            this.modal.show();
            this.showing = true;
        },
        active(){
            if(this.active){
                this.modal.show();
                this.showing = true;
            }else{
                this.modal.hide()
                this.showing = false;
            }
        }
    },
    beforeUnmount(){
        this.modal.hide();
        this.showing = false;
    },
    mounted(){
        this.modal = new Modal(this.$refs.commonModal, {
            backdrop: "static",
            keyboard: false,
        });
        if(this.active){
            this.modal.show();
            this.showing = true;
        }else{
            this.modal.hide();
            this.showing = false;
        }
    },
    data(){
        return{
            modal:null,
            showing:false,
            phrases:{
                f1:{
                    es:"Ok, enterado",
                    en:"Ok"
                }
            },
            a29:require('@/assets/images/expedient/a29.svg')
        }
    },
    methods:{
        hideModal(){
            this.$emit('close');
            this.modal.hide();
            this.showing = false;
        },
        makeAction(value){
            this.$emit('action', value);
            this.modal.hide();
            this.showing = false;
        },
        tryClose(){
            if(this.buttons.length > 0){
                this.$emit('action', 0);
            }
            this.hideModal()
        }
    }
}
</script>
<style scoped lang="css">

    .modal-content{
        border: 0 !important;
        background-color: transparent !important;
    }

    .titled{
        font-size: 28px;
        color:var(--black)
    }

    .contented{
        font-size: 18px;
        color:var(--black)
    }

    .modal-content{
        opacity: 0;
        animation: show 1s 1 0s forwards;
        max-height:50vh
    }

    .modal-body{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        background-color: #fff;
        min-height: 50vh;
        border-radius: 50px;
    }
    
    .commonModal .sucessButton{
        width: 50%;
        border: 1px solid var(--undecagenary);
        outline: none;
        background-color: #fff;
        border-radius: 10px;
    }

    .commonModal .sucessButton:hover{
        background-color: var(--undecagenary);
        color:#fff;
    }

    .buttonAction{
        font-size: 18px;
        background-color: var(--white);
        color:var(--decagenary);
        border: 2px solid var(--decagenary);
        outline: none;
        border-radius: 9px;
        pointer-events: none;
        min-width: 250px;
        height: 60px;
    }

    .buttonAction.off{
        color:var(--black);
    }

    .buttonAction.pushable{
        pointer-events: all;
        cursor: pointer;
    }

    .buttonAction.active{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--white);
    }

    .buttonAction.pushable:hover{
        background-color: var(--primary);
        border: 2px solid var(--primary);
        color:var(--white);
        opacity: 0.9;
    }

    .closeButton{
        display: flex;
        justify-content:center;
        align-items: center;
        align-content: center;
        position: absolute;
        right: 30px;
        top: -15px;
        width: 150px;
        border-radius: 9px;
        height: 80px;
        background-color: var(--dodecagenary);
        z-index: 2;
        cursor: pointer;
    }

    .closeButton:hover{
        transform: scale(1.1);
    }

    ::v-deep(.closeButton svg){
        width: 50px;
        height: 50px;
    }
    ::v-deep(.closeButton svg path){
        fill:#fff;
    }
    
</style>