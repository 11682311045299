<template lang="html">

        <div class="w-100 selectorSpaceContainer mt-2">
            <div class="selectorSpaceWidth d-flex justify-content-start">
                <div class="selectSimulatorContainer input-text d-flex justify-content-start position-relative">

                    <div v-if="items.length > 1" class="buttonActionChange" :class="choosingFilter > 0 ? 'open' : 'close'" ref="selector2" @click="chooseFilter()"></div>

                    <div class="listerFloating font-7"  :class="choosingFilter > 0  ? (choosingFilter > 1  ? 'down' : 'up' ) : 'close'" ref="selector1" :style="{ 'max-height': maxHeight + 'px' }">
                        <slot name="options" :items="items" :setFilter="setFilter"></slot>

                        <template v-if="!this.$scopedSlots.hasOwnProperty('options')">
                            <template v-if="keyShow == ''">
                                <div v-for="(item, itepos) in items" class="py-2 lineOption" @click="setFilter(item)">
                                    {{item}}
                                </div>
                            </template>
                            <template v-else>
                                <div v-for="(item, itepos) in items" class="py-2 lineOption" @click="setFilter(item[(keyValue != '') ? keyValue : keyShow])">
                                    {{item[keyShow]}}<template v-if="keyShowExtra != ''">&nbsp;{{item[keyShowExtra]}}</template>
                                </div>
                            </template>
                        </template>
                    </div>

                    <div @click="makeAction()" class="selectSimulator d-flex align-items-center align-content-center justify-content-start w-100 font-7">
                        <div v-if="value == ''" class="valuePlaceholder">
                            Seleccionar
                        </div>
                        <div v-else class="validValue">
                            <slot name="optionSelected" :items="items" :value="value" :passDate="passDate"></slot>

                            <template v-if="!this.$scopedSlots.hasOwnProperty('optionSelected')">
                                <div class="value">
                                    <div class="textContainer">
                                        <div class="textData">

                                            <template v-if="keyShow != '' && keyValue != ''">
                                                <template v-for="(item, itepos) in items">
                                                    <template v-if="item[keyValue] == value">{{item[keyShow]}}<template v-if="keyShowExtra != ''">&nbsp;{{item[keyShowExtra]}}</template></template>
                                                </template>
                                            </template>

                                            <template v-else> 
                                                {{value}}
                                            </template>

                                        </div>
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>

                </div>
            </div>
        </div>

</template>
<script>
export default {
    name:"selectorItem",
    emits:['input', 'change', 'action'],
    props: {
        items: [Array, Object],
        keyShow:{
            type:String,
            default:''
        },
        keyShowExtra:{
            type:String,
            default:''
        },
        keyValue:{
            type:String,
            default:''
        },
        disabling:{
            type: Boolean,
            default: false,
        },
        value:'',
    },
    data(){
        return{
            choosingFilter:0,
            maxHeight:100,
        }
    },
    beforeDestroy() {
		document.removeEventListener('click', this.handleClick);
	},
    mounted(){
        document.addEventListener('click', this.handleClick);
    },
    methods:{

        makeAction(){
            this.$emit('action')
        },

        passDate(date){
            /* comprueba si una fecha es menor que hoy */
            let now = new Date();
            let [year, month, day] = date.split("-");
            let dateTransformed = new Date(year, month-1, day);
            return dateTransformed < now;
        },

        handleClick(event) {
            if(this.$refs.selector1 && this.$refs.selector2){
                if (!this.$refs.selector1.contains(event.target) && !this.$refs.selector2.contains(event.target)) {
                    this.choosingFilter = 0;
                    this.maxHeight = 100;
                }
            }
        },

        chooseFilter(){
            if(this.disabling == true){
                return false
            }
            const dF = window.innerHeight - this.$refs.selector2.getBoundingClientRect().bottom;
			if(dF > 150){
				this.choosingFilter = 1; //abajo
                this.maxHeight = (dF - 20);
			}else{
				this.choosingFilter = 2; //arriba
                this.maxHeight = (window.innerHeight - 150);
			}
        },

        setFilter(fil){
            this.$emit('input', fil);
            this.$emit('change', fil);
            this.choosingFilter = 0;
            this.maxHeight = 100;
        }
    }
}
</script>
<style scoped lang="css">
.selectorSpaceContainer {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
    position:relative;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.selectorSpaceWidth {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	padding: 0;
}

.selectSimulatorContainer{
	width: 100%;
}

.blockButton .selectSimulator{
    opacity: 0.5;
    pointer-events: none;
}

/* valor de seleccion mostrado */
.valuePlaceholder{
    color: #cccccc !important;
}
.validValue{
    width:100%;
    max-width:100%;
    height:100%;
}
.value{
    width:100%;
    height:100%;
    display:flex;
    align-content:center;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
    max-width:100%;
    white-space: nowrap;
    overflow: hidden;
    word-break:keep-all;               
    text-overflow: ellipsis;
    padding-right: 30px
}
.value:not(:first-of-type){
    display:none !important
}
.textContainer{
    display:flex;
    flex:1;
    height:100%;
    overflow:hidden;
    align-items:center;
    align-content:center;
    padding-top:2px;
}

.textData{
    width:100%;
    display:inline-block;
    text-align:left;
    max-width:100%;
    white-space: nowrap;
    overflow: hidden;
    word-break:keep-all;               
    text-overflow: ellipsis;
}

.circle{
    display:inline-flex;
    flex-shrink: 0;
    width:20px;
    padding-top:10px;
    height:20px;
    border-radius:50%;
    margin-right:10px;
    background-color:var(--quinary)
}

.circle.active{
    background-color:var(--undecagenary)
}

.selectSimulator{
    max-width:100%;
    width: 100%;
	position: relative;
	text-align: center;
	padding: 0 10px 0 5px;
    color:#fff;
    background-color: var(--primary);
    width: 100%;
    border-radius: 11px;
    height: 45px;
    outline: none;
    padding-left: 15px;
    cursor: pointer;
}

.selectSimulator:hover{
    opacity: 0.9;
}

.selectSimulator:active{
    transform: scale(0.95);
}

.selectSimulator img:first-child{
	position: absolute;
	left: 20px;
}

.selectSimulator img:last-child{
	position: absolute;
	right: 20px;
}
.selectSimulator img:not(.invert):last-child{
	position: absolute;
	right: 20px;
}

.listerFloating::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
    padding-left:20px;
}

.listerFloating::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius:11px;
    cursor: pointer;
}

.listerFloating.close{
	height: 0;
	opacity:0;
}
.listerFloating:not(.close){
	height: auto;
    overflow-y:auto;
	opacity:1;
}
.listerFloating.up{
	top:105%;
}
.listerFloating.down{
	bottom:105%;
}
.listerFloating{
	position:absolute;
	left:0;
	width:100%;
	background-color:rgba(105, 105, 105, 0.9);
	color:#fff;
	font-size:16px;
	border-radius:11px;
	z-index: 1000;
	overflow: hidden;
	transition: height 0.1s linear !important;
}
.lineOption{
	cursor:pointer;
	width: 100%;
	height: 40px;
	padding-left:15px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lineOption:hover{
	background-color:var(--primary)
}

.buttonActionChange{
    width: 100px;
    height: 100%;
    position: absolute;
    z-index: 10;
    right:0;
    top:0;
    border-radius: 0 11px 11px 0;
    border-left: 1px solid var(--decagenary);
    background-color: var(--primary);
    cursor: alias;
}

.buttonActionChange:hover{
    opacity: 0.5;
}

.buttonActionChange.open::after{
    transform: rotate(-180deg) !important;
    transform-origin: 50% 135%;
}
.buttonActionChange.close::after{
    transform: rotate(0deg);
    transform-origin: 50% 135%;
}

.buttonActionChange::after{
    transition: all 0.2s;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5Ljc2IiBoZWlnaHQ9IjcuMTMiIHZpZXdCb3g9IjEyODEuNjg3IDMwMyA5Ljc2IDcuMTMiPjxwYXRoIGQ9Im0xMjgxLjY4OCAzMDUuMjU3IDIuMTktMi4yNTcgMi4yNCAyLjIzNy4wNDYuMDIuMzkuMzkgMi42MzctMi42MzcgMi4yNTYgMi4xOTEtMi4yMzYgMi4yNC0uMDIuMDQzLS40LjQuMDA5LjAwOS0yLjI0IDIuMjM3LTQuODcyLTQuODczWiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkYXRhLW5hbWU9IlVuacOzbiA1NSIvPjwvc3ZnPg==");
    display: flex;
    position: absolute;
    right:25px;
    bottom: 25px;
    width: 10px;
    height: 10px;
    pointer-events: none;
    background-color: transparent;
}

</style>