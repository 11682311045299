<template lang="html">
    <div id="shareModal" class="bg-white px-xl-5 px-1 d-flex flex-column justify-content-center align-items-start align-content-start flex-nowrap">

        <div class="w-100 headModal d-flex flex-row mt-4 align-items-center ps-5 py-5 py-lg-0">
            <div class="flex-fill h-100 d-flex flex-nowrap align-items-center ps-5 align-content-center text-s-1 d-flex fw-bold">
                Compartir archivos
            </div>
            <div class="iconContainer close py-3 px-3" @click="closeModal()"><img v-svg-inline :src="a5"></div>
        </div>

        <div class="col-12 mainModal mb-5 d-flex flex-fill justify-content-center mt-1 px-2 align-items-start align-content-start">
            <div class="col-12 col-lg-10 h-100 listSide d-flex flex-wrap justify-content-center align-items-start align-content-start">

                <div class="col-11 px-lg-3 px-0 d-flex justify-content-center mt-2 mt-xl-5 fw-bold">
                    <div class="inputShareContainer inset col-12 position-relative d-flex flex-wrap align-items-center align-content-center">
                        <span :class="Object.keys(doctorsMatched).length > 0 ? 'finded' : 'zero'"><img v-svg-inline :src="a27" ></span>
                        <input @input="getMatchDoctors()" type="text" placeholder="Contactos médicos" maxlength="150" ref="doctorName" v-model="doctorName">
                    </div>
                </div>

                <div class="col-11 px-lg-3 px-0 d-flex flex-wrap justify-content-center mt-4 fw-bold">

                    <div v-if="doctorName == ''" class="matchData col-12 py-1 position-relative d-flex flex-wrap flex-sm-nowrap flex-md-wrap align-items-center align-content-center" :class="preData.shared.patient ? 'shared ps-5 ps-md-3 ps-xl-0' : 'notshared px-5 px-md-4'">

                        <div v-if="preData.shared.patient" class="order-md-0 order-1 col-4 auto col-md-auto d-flex justify-content-center align-content-center align-items-center" style="min-width:120px">

                            <div v-if="timers['wpatient'] <= 0" class="iconContainer close d-flex justify-content-center" @click="unshareWith('patient')">
                                <img :src="a5" class="unshared">
                            </div>

                            <div v-else class="containerTimer d-flex justify-content-center justify-content-xl-end align-items-center align-content-center pe-xl-3">
                                <div class="timerAction d-flex justify-content-center justify-content-xl-end align-items-center align-content-center" data-percentage="100">
                                    <div class="inner d-flex justify-content-center align-items-center align-content-center" @click="cancelTimer('patient');">
                                        cancelar
                                    </div>
                                    <svg class="r-timer" width="100" height="100" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="guide" r="40" cx="50" cy="50" fill="transparent" stroke-dasharray="300" stroke-dashoffset="0"></circle>
                                        <circle class="time" r="40" cx="50" cy="50" fill="transparent" stroke-dasharray="300" :stroke-dashoffset="300-(timers['wpatient']*3)"></circle>
                                    </svg>
                                </div>
                            </div>

                        </div>
    
                        <div class="order-0 order-md-1 col-8 d-flex flex-wrap align-items-center align-content-center ps-md-3">

                            <div class="col-12 matchName"><span>Pte.</span> {{this.patient.name}} {{this.patient.lastname}}</div>

                            <div class="col-12 d-flex flex-wrap align-items-start align-content-start">

                                <div class="col-xl-8 col-md-7 col-12 d-flex flex-wrap matchInfo ps-3 mt-2 mt-lg-0">
                                    <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                        <span class="pe-2"><img v-svg-inline :src="a7"></span>
                                        <span class="px-2 dataEditable" @paste="limitPaste($event, 100)" @keypress="limit($event, 100)" v-on:blur="sendChanges($event, 0, 'email', 'email')">{{this.patient.email||'----'}} </span>
                                    </span>
                                    <span class="w-100 ps-5 matchInfoEdit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                </div>
                            
                                <div class="col-xl-4 col-md-5 col-12 d-flex flex-wrap matchInfo ps-3 ps-lg-0 mt-2 mt-lg-0">
                                    <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                        <span class="pe-2"><img v-svg-inline :src="a8"></span>
                                        <span class="px-2 dataEditable" @paste="limitPaste($event, 10, true)" @keypress="limit($event, 10, true)" v-on:blur="sendChanges($event, 0, getPhone()[1], 'phone')">{{getPhone()[0]}}</span>
                                    </span>
                                    <span class="w-100 ps-5 matchInfoEdit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                </div>

                            </div>

                        </div>
   
                        <div v-if="!preData.shared.patient" class="shareAction order-2 col-sm-4 col-12 d-flex justify-content-sm-end justify-content-center  align-items-center align-content-center">
                            <button v-if="timers['wpatient'] <= 0" class="px-5 px-sm-1 px-md-4" @click="shareWith('patient')">Compartir</button>

                            <div v-else class="containerTimer d-flex justify-content-center justify-content-xl-end align-items-center align-content-center pe-xl-5">
                                <div class="timerAction d-flex justify-content-center justify-content-xl-end align-items-center align-content-center" data-percentage="100">
                                    <div class="inner d-flex justify-content-center align-items-center align-content-center" @click="cancelTimer('patient');">
                                        cancelar
                                    </div>
                                    <svg class="r-timer" width="100" height="100" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="guide" r="45" cx="50" cy="50" fill="transparent" stroke-dasharray="300" stroke-dashoffset="0"></circle>
                                        <circle class="time" r="45" cx="50" cy="50" fill="transparent" stroke-dasharray="300" :stroke-dashoffset="300-(timers['wpatient']*3)"></circle>
                                    </svg>
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <template v-if="doctorName == ''">
                        <template v-for="idDoctor in preData.shared.doctors">

                            <div v-if="doctors.hasOwnProperty(idDoctor)" class="matchData ps-5 ps-md-3 ps-xl-0 mt-3 col-12 py-1 position-relative d-flex flex-nowrap flex-md-wrap align-items-center align-content-center shared">

                                <div class="col-4 order-md-0 order-1 auto col-md-auto d-flex justify-content-center align-content-center align-items-center" style="min-width:120px">

                                    <div v-if="timers['w'+idDoctor] <= 0" class="iconContainer close d-flex justify-content-center" @click="unshareWith(idDoctor, 'doctor')">
                                        <img :src="a5" class="unshared">
                                    </div>

                                    <div v-else class="containerTimer d-flex justify-content-center justify-content-xl-end align-items-center align-content-center pe-xl-3">
                                        <div class="timerAction d-flex justify-content-center justify-content-xl-end align-items-center align-content-center" data-percentage="100">
                                            <div class="inner d-flex justify-content-center align-items-center align-content-center" @click="cancelTimer('patient');">
                                                cancelar
                                            </div>
                                            <svg class="r-timer" width="100" height="100" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <circle class="guide" r="40" cx="50" cy="50" fill="transparent" stroke-dasharray="300" stroke-dashoffset="0"></circle>
                                                <circle class="time" r="40" cx="50" cy="50" fill="transparent" stroke-dasharray="300" :stroke-dashoffset="300-(timers['w'+idDoctor]*3)"></circle>
                                            </svg>
                                        </div>
                                    </div>

                                </div>
            
                                <div class="col-8 order-md-1 order-0 d-flex flex-wrap align-items-center align-content-center ps-md-3">

                                    <div class="col-12 matchName"><span>{{format(doctors[idDoctor].name, 1)}}</span> {{format(doctors[idDoctor].name, 2)}}</div>

                                    <div class="col-12 d-flex flex-wrap align-items-start align-content-start">

                                        <div class="col-xl-8 col-md-7 col-12 d-flex flex-wrap matchInfo ps-3 mt-2 mt-lg-0">
                                            <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                                <span class="pe-2"><img v-svg-inline :src="a7"></span>
                                                <span class="px-2 dataEditable" @paste="limitPaste($event, 100)" @keypress="limit($event, 100)" v-on:blur="sendChanges($event, idDoctor, 'email', 'email')">{{doctors[idDoctor].email||'----'}} </span>
                                            </span>
                                            <span class="w-100 ps-5 matchInfoEdit edit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                        </div>
                                    
                                        <div class="col-xl-4 col-md-5 col-12 d-flex flex-wrap matchInfo ps-3 ps-lg-0 mt-2 mt-lg-0">
                                            <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                                <span class="pe-2"><img v-svg-inline :src="a8"></span>
                                                <span class="px-2 dataEditable" v-on:blur="sendChanges($event, idDoctor, 'phone', true, 'phone')" @paste="limitPaste($event, 10, true)"  @keypress="limit($event, 10, true)">{{doctors[idDoctor].phone
                                            ||'----'}}</span>
                                            </span>
                                            <span class="w-100 ps-5 matchInfoEdit edit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                        </div>

                                    </div>

                                </div> 
                                
                            </div>
                            
                        </template>
                    </template>

                    <div v-for="(doctorMatched, dKey) in doctorsMatched" class="matchData col-12 py-1 position-relative d-flex flex-wrap align-items-center align-content-center notshared mt-3 px-5 px-md-4">
    
                        <div class="col-12 col-xl-8 d-flex flex-wrap align-items-center align-content-center ps-md-3">
    
                            <div class="col-12 matchName my-3 my-xl-0"><span>{{format(doctorMatched.name, 1)}}</span> {{format(doctorMatched.name, 2)}}</div>

                            <div class="col-12 d-flex flex-wrap align-items-start align-content-start">

                                <div class="col-xl-8 col-md-7 col-12 d-flex flex-wrap matchInfo ps-3 mt-2 mt-lg-0">
                                    <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                        <span class="pe-2"><img v-svg-inline :src="a7"></span>
                                        <span class="px-2 dataEditable" @paste="limitPaste($event, 100)" @keypress="limit($event, 100)" v-on:blur="sendChanges($event, dKey, 'email', 'email')">{{doctorMatched.email||'----'}} </span>
                                    </span>
                                    <span class="w-100 ps-5 matchInfoEdit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                </div>
                            
                                <div class="col-xl-4 col-md-5 col-12 d-flex flex-wrap matchInfo ps-3 ps-lg-0 mt-2 mt-lg-0">
                                    <span class="w-100 pe-2 d-flex" style="word-break:break-all">
                                        <span class="pe-2"><img v-svg-inline :src="a8"></span>
                                        <span class="px-2 dataEditable" @paste="limitPaste($event, 10, true)" @keypress="limit($event, 10, true)" v-on:blur="sendChanges($event, dKey, 'phone', 'phone')">{{doctorMatched.phone
                                    ||'----'}}</span>
                                    </span>
                                    <span class="w-100 ps-5 matchInfoEdit" @click="edit($event)">&nbsp;&nbsp;editar</span>
                                </div>

                            </div>

                        </div>

                        <div class="shareAction col-xl-4 col-12 d-flex justify-content-xl-end justify-content-center align-items-center align-content-center">

                            <button v-if="timers['w'+dKey] <= 0" class="px-5 px-lg-4" @click="shareWith(dKey, 'doctor')">Compartir</button>

                            <div v-else class="containerTimer d-flex justify-content-center justify-content-xl-end align-items-center align-content-center pe-xl-5">
                                <div class="timerAction d-flex justify-content-center justify-content-xl-end align-items-center align-content-center" data-percentage="100">
                                    <div class="inner d-flex justify-content-center align-items-center align-content-center" @click="cancelTimer(dKey);">
                                        cancelar
                                    </div>
                                    <svg class="r-timer" width="100" height="100" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <circle class="guide" r="45" cx="50" cy="50" fill="transparent" stroke-dasharray="300" stroke-dashoffset="0"></circle>
                                        <circle class="time" r="45" cx="50" cy="50" fill="transparent" stroke-dasharray="300" :stroke-dashoffset="300-(timers['w'+dKey]*3)"></circle>
                                    </svg>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>

//import axios from "axios";

export default {
    name:"shareModal",
    emits:['close', 'saveTemp', 'shareMovement', 'editDoctor'],
    props:{
        doctors:{},
        wizard:false,
        preData:{},
        con:{},
        patient:{},
        auth:''
    },
    watch:{
        preData: {
            handler: function (val, oldVal) {
                this.getMatchDoctors();
            },
            deep: true
        }
    },
    data(){
        return{
            a5:require('@/assets/images/expedient/a5.svg'),
            a7:require('@/assets/images/expedient/a7.svg'),
            a8:require('@/assets/images/expedient/a8.svg'),
            a27:require('@/assets/images/expedient/a27.svg'),

            doctorsMatched:{},
            doctorName:"",

            timers:{
                'wpatient':0
            },
            clearDoctors:setTimeout(() => {this.hideDoctors()}, 100),

            lastValue:"",
            editing:false
        }
    },
    beforeMount(){
       this.setTimers();
    },
    methods:{

        hideDoctors(){
            this.doctorName = "";
            this.doctorsMatched = {};
        },

        runCloseDoctor(){
            clearTimeout(this.clearDoctors);
            this.clearDoctors = setTimeout(() => {this.hideDoctors()}, 3000);
        },

        stopCloseDoctor(){
            clearTimeout(this.clearDoctors);
        },

        getPhone(){
            let numbers = {
                p1:this.patient.phone1.replace(/\D/g, ''),
                p2:this.patient.phone2.replace(/\D/g, '')
            }

            if(numbers.p1.length == 10 && this.patient.phone1Type == 'Celular'){
                return [numbers.p1, 'phone1']
            }
            if(numbers.p2.length == 10 && this.patient.phone2Type == 'Celular'){
                return [numbers.p2, 'phone2']
            }
            if(numbers.p1.length == 10){
                return [numbers.p1, 'phone1']
            }
            if(numbers.p2.length == 10){
                return [numbers.p2, 'phone2']
            }else{
                return ["", 'phone1']
            }
        },

        limit(e, lim, numbers=false){
            if(e.target.textContent.length > (lim-1)){
                e.preventDefault();
                return false
            }
            if(numbers && !(e.charCode >= 48 && e.charCode <= 57)){
                e.preventDefault();
                return false
            }

        },
        limitPaste(e, lim, numbers=false){
            e.preventDefault();
            let clipboardData, pastedData;
            clipboardData = e.clipboardData || window.clipboardData;
            pastedData = clipboardData.getData('Text');
            if(numbers){
                pastedData = pastedData.replace(/\D/g, '');
            }
            if(pastedData != "" && pastedData != null){
                pastedData = pastedData.slice(0, lim);
                e.target.innerText = pastedData;
            }
        },
        setError(e){
            e.target.classList.add('errorInput');
            e.target.innerText = this.lastValue;
            setTimeout(()=>{
                try{
                    e.target.classList.remove('errorInput');
                    this.editing = false;
                    this.lastValue = "";
                }catch(err){}
            }, 2000)
        },
        setSuccess(e){
            e.target.classList.add('successInput');
            setTimeout(()=>{
                try{
                    e.target.classList.remove('successInput');
                    this.editing = false;
                    this.lastValue = "";
                }catch(err){}
            }, 2000);
        },
        sendChanges(e, id, type, interType=''){
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            if(this.editing){
                return false
            }
            this.editing = true;
            if(interType == 'phone'){
                if(e.target.textContent.length != 10){
                    this.setError(e);
                    return false
                }
                
            }
            else if(interType == 'email'){
                if(!( /^[a-zA-Z0-9_.]{3,64}@[a-z0-9]{3,64}\.[a-z0-9]{2,10}(?:\.[a-z]{2,10}|)$/g).test(e.target.textContent)){
                    this.setError(e);
                    return false
                }
            }

            this.setSuccess(e);
            this.$emit('editDoctor', id, type, e.target.textContent);
        },
        edit(e){
            if(this.lastValue != "" || this.editing){
                return false
            }
            this.lastValue = e.target.parentNode.firstChild.lastChild.textContent;
            e.target.parentNode.firstChild.lastChild.setAttribute("contenteditable", true);
            e.target.parentNode.firstChild.lastChild.classList.add("edit");
            e.target.parentNode.firstChild.lastChild.focus();
            try{
                document.execCommand('selectAll', false, null);
                document.getSelection().collapseToEnd();
            }catch(err){}
        },
        count(id){
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    if(this.timers['w'+id] == -1){
                        this.timers['w'+id] = 0;
                        this.$forceUpdate();
                        resolve(false)
                    }else{
                        this.timers['w'+id] += 10;
                        this.$forceUpdate();
                        if(this.timers['w'+id] > 101){
                            resolve(true)
                        }else{
                            this.count(id).then((res)=>{
                                resolve(res)
                            })
                        }
                    }
                },250)
            })
        },
        setTimers(){
            for(let i in this.doctors){
                this.timers['w'+i] = 0;
            }
        },
        cancelTimer(id){
            this.timers['w'+id] = -1;
            this.stopCloseDoctor();
        },
        unshareWith(id=0, type="patient"){
            this.timers['w'+id] = 1;
            this.$forceUpdate();
            setTimeout(()=>{
                this.timers['w'+id] += 10;
                this.$forceUpdate();
            }, 100)
            this.count(id).then((res)=>{
                if(res){
                    this.timers['w'+id] = 0;
                    let nShared = {...this.preData.shared};
                    switch(type){
                       
                        case "doctor":
                            let pos = nShared.doctors.indexOf(id);
                            if(pos != -1){
                                nShared.doctors.splice(pos, 1);
                            }
                            this.share('remove', 'doctor', id);
                            break;
                        case "patient":
                        default:
                            this.share('remove', 'patient', id);
                            nShared.patient = false;
                            break;
                    }
                    this.$emit('saveTemp', {'shared':nShared}, false, '', '', true);
                    this.$emit('shareMovement', nShared);
                    
                }
            })
        },
        shareWith(id=0, type="patient"){
            this.timers['w'+id] = 1;
            this.$forceUpdate();
            setTimeout(()=>{
                this.timers['w'+id] += 10;
                this.$forceUpdate();
            }, 100);
            this.runCloseDoctor();
            this.count(id).then((res)=>{
                if(res){
                    this.timers['w'+id] = 0;
                    let nShared = {...this.preData.shared};
                    switch(type){
                        case "doctor":
                            nShared.doctors.push(Number(id))
                            this.share('add', 'doctor', id);
                            break;
                        case "patient":
                        default:
                            nShared.patient = true;
                            this.share('add', 'patient', id);
                            break;
                    }
                    this.$emit('saveTemp', {'shared':nShared}, false, '', '', true);
                    this.$emit('shareMovement', nShared);
                }
            })
            
        },
        share(type, user, id=0){
            return new Promise((resolve, reject) =>{
                let requestBody = {
                    "idPatient": this.patient.idPatient,
                    "date": this.preData.date,
                    "folder": this.preData.folder,
                    "share": {}
                }

                switch(user){
                    case 'patient':
                        requestBody.share.patient =  true;
                        break;
                    case 'doctor':
                        requestBody.share.doctor =  id;
                        break;
                }

                switch(type){
                    case 'add':
                        this.con.share({token: this.auth, data:requestBody}).then((res) => {
                            if(res.code == 200){
                                resolve(true)
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                        /*axios.post(this.con.api+'apiOnico/index.php/Shared', requestBody, this.con.headers)
                        .then((res) => {
                            if(res.status == 200){
                                resolve(true)
                            }
                        })
                        .catch((err) => {console.log(err)})*/

                        break;
                    case 'remove':

                        this.con.unshare({token: this.auth, data:requestBody}).then((res) => {
                            if(res.code == 200){
                                resolve(true)
                            }
                        }).catch((error) => {
                            console.log(error)
                        });
                        
                        /*let config = {...this.con.headers};
                        config.data = requestBody;

                        axios.delete(this.con.api+'apiOnico/index.php/Shared', config)
                        .then((res) => {
                            if(res.status == 200){
                                resolve(true)
                            }
                        })
                        .catch((err) => {console.log(err)})*/
                        break;
                }
                
            })
            
            
        },
        format(string, style){
            let result = "";
            switch(style){
                case 1:
                    result = string.split(" ");
                    result = result[0] == "Dra" || result[0] == "Dr" ? result[0] : "";
                    return result
                case 2:
                    result = string.split(" ");
                    result = result[0] == "Dra" || result[0] == "Dr" ? result.slice(1).join(' ') : string;
                    return result
                default:
                    return result
            }
        },
        getMatchDoctors(){
            this.doctorsMatched = {};

            if(this.doctorName.length >= 3){
                for (let i in this.doctors) {
                    if(this.doctors[i].email.toLowerCase().includes(this.doctorName.toLowerCase()) 
                    || (this.doctors[i].name.toLowerCase().normalize("NFKD").replace(/[\u0300-\u036F]/g, "")).includes(this.doctorName.toLowerCase().normalize("NFKD").replace(/[\u0300-\u036F]/g, "")) 
                    || this.doctors[i].phone.includes(this.doctorName.toLowerCase())){
                        if(!this.preData.shared.doctors.includes(Number(i)) && !this.preData.shared.doctors.includes(i)){
                            this.doctorsMatched[i] = this.doctors[i];
                        }
                        continue
                    }
                }
            }

        },
        closeModal(){
            this.$emit('closeModal', 'share');
        },
    }
}
</script>
<style lang="css">
    #shareModal{
        position: fixed;
        left: 5vw;
        top: 5vh;
        width: 90vw;
        height: 90vh;
        z-index: 2000;
        overflow-y: auto;
        filter: drop-shadow(0px 12px 23px rgba(0,0,0,0.16 ));
        border-radius: 44px;
    }
    #shareModal .headModal{
        height: auto !important;
        min-height: 70px !important;
    }
    .mainModal{
        height: calc(70% - 70px);
    }
    .inputShareContainer{
        height: auto;
        transition: margin 0.5s;
    }

    .inputShareContainer input.inset{
        padding-right: 300px;
        height: 141px !important;
        padding-left: 140px !important;
        font-size: 42px !important;
    }

    .inputShareContainer input{
        width: 100%;
        height: 110px;
        font-size: 28px;
        padding-left: 120px;
        padding-right: 30px;
        color: #444;
        font-weight: bold;
        border:6px solid #D5D5D5;
        border-radius: 25px;
        outline:none;
    }

    .inputShareContainer span{
        position: absolute;
        left: 40px;
        height: 70px;
        width: 70px;
    }

    .finded svg path{
        fill: #2599fb;
    }
    .zero svg path{
        fill: #D5D5D5;
        stroke: #fff;
    }

    .matchData{
        width: 100%;
        font-size: 28px;
        color: #444;
        font-weight: bold;
        border:6px solid #D5D5D5;
        border-radius: 30px;
        outline:none;
    }

    .matchData>div:not(.auto){
        min-height: 100px;
    }

    .matchData.shared{
        background-color: #2599fb;
        border:6px solid #2599fb;
    }

    .matchData .shareAction button{
        max-width: 250px;
        height: 76px;
        border-radius: 19px;
        background-color: #2599fb;
        color:#fff;
        font-size: 28px;
        position: absolute;
        border: 0;
        outline: 0;
    }
    .matchInfo{
        font-size: 18px;
        color:#8B8B8B
    }
    .matchName{
        line-height: 1.1;
        font-size: 26px;
    }

    .matchData.notshared .matchName span{
        color:#8B8B8B
    }
    .matchData.notshared svg path{
        fill:#D5D5D5 !important
    }

    .matchData.shared .matchName span{
        color:#63F3FF
    }
    .matchData.shared svg:not(.unshared) path{
        fill:#63F3FF !important
    }
    .matchData.shared svg.unshared path{
        fill:#fff !important
    }
    .matchData.shared .matchInfoEdit{
        color:#63F3FF;
        cursor: pointer;
    }
    .matchData.shared .matchInfoEdit:hover{
        color:#D5D5D5;
    }
    .matchData.notshared .matchInfoEdit:hover{
        color:#2599fb;
    }
    .matchData.shared .matchName{
        color:#fff
    }
    .matchData.shared .matchInfo{
        color:#fff
    }
    .matchData.shared .shareAction{
        display: none !important;
    }
    .matchData .matchInfoEdit{
        font-size: 14px;
        line-height: 1;
        cursor: pointer;
    }
    
    .listSide{
        overflow-x: hidden;
        overflow-y: auto;
    }
    .listSide::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }

    .listSide::-webkit-scrollbar-thumb {
        background-color: rgba(241, 87, 83, 0.6);
        border-radius: 7px;
    }
    .footModal{
        height: 30%;
    }

    .containerTimer {
        width: 100%;
        height: 100%;
    }

    .r-timer{
        pointer-events: none;
        object-fit: contain;
    }

    .r-timer > circle {
        transition: stroke-dashoffset 0.25s linear;
        stroke-width: 7px;
        stroke-linecap: round;
    }

    .notshared .r-timer > .guide {
        stroke: #D5D5D5;
    }

    .shared .r-timer > .guide {
        stroke: #fff;
    }

    .notshared .r-timer > .time {
        stroke: #2196f3;
    }
    .shared .r-timer > .time {
        stroke: #F15753;
    }

    .shared .timerAction svg{
        filter: drop-shadow(1px 1px 1px rgba(200,200,200,0.3));
    }

    .notshared .timerAction svg{
        filter: drop-shadow(1px 1px 1px #D5D5D5);
    }


    .timerAction:hover .inner{
        color: #ff0000 !important;
        text-shadow: 1px 1px 1px rgba(200,200,200,0.3);
        transform: scale(1.05);
        transform-origin: 50% 80%;
    }

    .timerAction{
        position: relative;
        cursor: pointer;
    }

    .timerAction > .inner {
        position: absolute;
        font-size: 15px;
        width: 100%;
        height: 100%;
    }

    .notshared .timerAction > .inner {
        color:#8B8B8B;
    }

    .shared .timerAction > .inner {
        color:#fff;
    }

    .dataEditable{
        border: 1px solid transparent;
        border-radius: 4px;
        font-size: 18px;
    }

    .shared .dataEditable[contenteditable=true]{
        outline: 0;
        color: #000;
        background-color: #fff;
    }

    .notshared .dataEditable[contenteditable=true]{
        outline: 0;
        color: #000;
        background-color: #D5D5D5;
    }

    .errorInput{
        animation: error 1.5s linear 1;
    }

    .successInput{
        animation: success 1.5s linear 1;
    }

    @keyframes success {
        0%{
            border: 1px solid transparent;
        }
        25%{
            color: #00ff00;
            border: 1px solid #00ff00;
        }
        50%{
            color: inherit;
            border: 1px solid transparent;
        }
        75%{
            color: #00ff00;
            border: 1px solid #00ff00;
        }
        100%{
            border: 1px solid transparent;
        }
    }

    @keyframes error {
        0%{
            border: 1px solid transparent;
        }
        25%{
            color: #ff0000;
            border: 1px solid #ff0000;
        }
        50%{
            color: inherit;
            border: 1px solid transparent;
        }
        75%{
            color: #ff0000;
            border: 1px solid #ff0000;
        }
        100%{
            border: 1px solid transparent;
        }
    }



</style>