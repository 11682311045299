<template>
    <wrapper :con="con"></wrapper>
</template>

<script>
import  { request } from '@/assets/js/requests.js';
import wrapper from '@/views/moduleContainer.vue';
export default {
    name:"modularManager",
    components:{
      wrapper
    },
    data(){
        return{
            con:new request(process.env.NODE_ENV == 'production' ? "https://videre-altabrisa.efisense.xyz/" : "https://videre-altabrisa.efisense.xyz/"),
        }
    }
}
</script>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
svg:focus{
  outline: 0;
}
html{
  height: 100%;
}
body{
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(241, 87, 83, 0.6);
  border-radius: 7px;
}

</style>
