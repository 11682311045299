<template>
    <div class="SurgicalNote module" v-if="fillValues">

        <loader :active="loading"></loader>
        <c-modal :active="false" :mess="commonModalMess" :title="commonModalTitle" :showIncrement="commonModalIgniter" :buttons="commonModalOptions" lang="es" @action="filterCModalAction"></c-modal>

        <div class="w-100 d-flex flex-wrap justify-content-betweem align-items-center align-content-center mb-4">
            <h2 class="text-start fw-bold my-3 txt-oscuro col-4">Nota Pre Quirúrgica</h2>
            <div class="d-flex col-lg-4 col-12 px-lg-3 px-xxl-4 justify-content-end">
                <div class="ps-lg-2 pe-lg-0 d-flex col-xxl-6 col-12 ps-4 pe-4">
                    <button class="addButton" @click="NewNote()">
                        <span class="ps-2">Nueva Nota Quirúrgica</span>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform:scale(0.8)">
                            <path fill-rule="evenodd" d="M11.25 12.75V18H12.75V12.75H18V11.25H12.75V6H11.25V11.25H6V12.75H11.25Z" fill="#fff" stroke="#fff" stroke-width="4"/>
                        </svg>
                    </button>
                </div>
                
            </div>
            <div class="d-flex col-lg-4 col-12 pe-lg-0 ps-lg-5 ps-4 pe-4">

                <aside class="w-100">
                    <selector v-model="selectedSurgicalNote" :items="surgicalNoteHistory"  @change="checkEditField()">
                        <template v-slot:options="{ items, setFilter }">
                            <template v-for="(surgicalJson, surgicalDate) in items">
                                <div v-for="(surgical, posSurgical) in surgicalJson" class="py-2 lineOption" @click="setFilter(surgical.idOnicoModuleSurgicalNote)">
                                    <span class="circle" :class="surgical.sign != ''  ? '' : 'active'"></span>{{surgicalDate+ ' - ' +surgical.description}}
                                </div>
                            </template>
                        </template>
                        <template v-slot:optionSelected="{ items, value, passDate }">
                            <template v-for="(surgicalJson, surgicalDate) in items">
                                <template v-for="(surgical, posSurgical) in surgicalJson">
                                    <div class="value" v-if="surgical.idOnicoModuleSurgicalNote == value">
                                        <span class="circle" :class="surgical.sign != '' ? '' : 'active'"></span>
                                        <div class="textContainer"><div class="textData">{{surgicalDate+ ' - ' +surgical.description}}</div></div>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </selector>
                </aside>

            </div>

           

        </div>

        <div class="sections d-flex w-100 flex-wrap h-100" :class="isDisabled ? 'blocked' : ''">
        
            <section class="w-100 ps-2 d-flex flex-wrap justify-content-between align-items-stretch align-content-stretch">

                <!-- ********** EMPIEZA EL ARTICLE 1 QUE ES EL IZQUIERDO************ -->

                <article class="col-xl-4 col-12 pe-lg-5 article-1 mb-5 mb-lg-0 d-flex flex-wrap align-items-start align-content-start">
                    <section class="w-100" ref="diags">
                        <p class="text-start fw-bold txt-oscuro mb-2">Diagnóstico</p>

                        <template>
                            <template v-for="(surgicalItem, surgicalItemPos) in formStorage.items.value">
                                <template v-if="surgicalItem.type == 'diag'">
                                    <aside class="d-flex align-items-center botons justify-content-start w-100 item">
                                        <label class="switch">
                                            <input type="checkbox" v-model="formStorage.items.value[surgicalItemPos].status"/>
                                            <span class="slider"></span>
                                        </label>
                                        <p class="mx-1 text-start botons-extraccion parrafo ps-2">
                                            <span class="nhb txt-oscuro">{{surgicalItem.cieCode}}</span> {{ surgicalItem.description }}
                                        </p>
                                    </aside>
                                </template>
                            </template>
                        </template>

                    </section>

                    <section class="mt-4 w-100" ref="treats">
                        <p class="text-start nhb txt-oscuro mb-2">Procedimiento</p>

                        <template>
                            <template v-for="(surgicalItem, surgicalItemPos) in formStorage.items.value">
                                <template v-if="surgicalItem.type == 'treat'">
                                    <aside class="d-flex align-items-center botons w-100 item">
                                        <label class="switch">
                                            <input type="checkbox" v-model="formStorage.items.value[surgicalItemPos].status"/>
                                            <span class="slider"></span>
                                        </label>
                                        <p class="mx-1 text-start botons-extraccion parrafo ps-2">
                                            <span class="nhb txt-oscuro">{{surgicalItem.cieCode}}</span> {{ surgicalItem.description }}
                                        </p>
                                    </aside>
                                </template>
                            </template>
                        </template>

                    </section>

                    <section class="w-100 d-flex justify-content-between mt-4" ref="eyeSwitchs">

                        <aside class="w-50 d-flex align-items-center botons" ref="eRight">
                            <label class="switch">
                                <input type="checkbox" v-model="formStorage.eRight.value" />
                                <span class="slider"></span>
                            </label>
                            <p class="mx-1 nhb txt-oscuro parrafo ps-2">Ojo Derecho</p>
                        </aside>

                        <aside class="w-50 d-flex align-items-center botons" ref="eLeft">
                            <label class="switch">
                                <input type="checkbox" v-model="formStorage.eLeft.value" />
                                <span class="slider"></span>
                            </label>
                            <p class="mx-1 nhb txt-oscuro parrafo ps-2">Ojo Izquierdo</p>
                        </aside>

                    </section>

                    <section class="w-100 d-flex mt-4">

                        <aside class="w-50 pe-2" ref="duration" :class="formStorage.assignedRoom.value == 0 ? 'pe-none':''">
                            <p class="nhb txt-oscuro text-start mb-2">Duración</p>
                            <input v-model="formStorage.duration.value" class="input-text" type="text"
                                placeholder="Min." required @keydown="onlyNumbers($event)" @paste="pasteFilterNumber($event)" @change="getAvailabilityInSchedule()"/>
                        </aside>

                        <aside class="w-50 ps-2 selectSpace" ref="complexity" :class="editBeforeSign('complexity') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">Complejidad</p>

                            <selector v-model="formStorage.complexity.value" :items="fillValues.complexity">
                            </selector>
                        </aside>

                    </section>

                    <section class="w-100 d-flex flex-wrap mt-4">

                        <aside class="w-100 d-flex flex-wrap m-0 p-0" ref="comments" :class="editBeforeSign('comments') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2 w-100">Comentarios</p>

                            <textarea class="article1-comentarios m-0" id="mensaje" name="mensaje" v-model="formStorage.comments.value" @input="filterText('comments')" maxlength="500"></textarea>
                        </aside>

                    </section>

                </article>

                <!-- ********** EMPIEZA EL ARTICLE 2 QUE ES EL DE ENMEDIO************ -->
                <article class="col-xl-4 col-12 px-lg-3 px-xxl-4 article-2 mb-5 mb-lg-0 d-flex flex-column flex-wrap align-items-start align-content-start">
                    <section class="d-flex flex-wrap w-100">

                        <aside class="col-6 pe-2" :class="editBeforeSign('treatingDoctor') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2 parrafo w-100">Médico tratante</p>

                            <selector v-model="formStorage.treatingDoctor.value" :items="fillValues.treatingDoctor" keyShow="name" keyShowExtra="lastName" keyValue="idUser">
                            </selector>
                        </aside>

                        <aside class="col-6 ps-2" :class="editBeforeSign('surgeon') ? 'open' : ''" ref="surgeon">
                            <p class="nhb txt-oscuro text-start mb-2 parrafo w-100">Cirujano</p>

                            <selector v-model="formStorage.surgeon.value" :items="fillValues.listDoctors" keyShow="name" keyShowExtra="lastName" keyValue="idUser">
                            </selector>
                        </aside>

                        <aside class="col-6 mt-4 pe-2" :class="editBeforeSign('assistantShip') ? 'open' : ''" ref="assistantShip">
                            <p class="nhb txt-oscuro text-start mb-2 w-100">Ayudantía</p>

                            <selector v-model="formStorage.assistantShip.value" :items="fillValues.assistantShip">
                            </selector>
                        </aside>

                        <aside class="col-6 mt-4 ps-2" :class="editBeforeSign('anesthesiologist') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2 w-100">Anestesiólogo</p>

                            <selector v-model="formStorage.anesthesiologist.value" :items="fillValues.anesthesiologist">
                            </selector>
                        </aside>
                    </section>

                    <section class="w-100 d-flex mt-4">

                        <aside class="w-100" ref="typeOfAnesthesia" :class="editBeforeSign('typeOfAnesthesia') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Tipo de anestesia
                            </p>

                            <selector v-model="formStorage.typeOfAnesthesia.value" :items="fillValues.typeOfAnesthesia">
                            </selector>
                        </aside>

                    </section>

                    <section class="w-100 d-flex toBottom mt-4 mt-lg-auto">
                        <aside class="w-100">

                            <p class="nhb txt-oscuro text-start mb-2 mt-4">
                                Imágenes relacionadas
                            </p>

                            <div class="custom-file" v-show="formStorage.surgicalFolderFiles.value.length <= 0">
                                <label for="fileDragSurgical" class="custom-file-label">
                                    <img src="../../assets/images/Enlace.svg" alt="Imagen de enlace">
                                    Adjuntar Imágenes</label>
                                <input id="fileDragSurgical" type="file" name="files[]" ref="fileDragSurgical" class="fileDrag" accept="image/png, image/jpg, image/jpeg, application/pdf" multiple @change="chargeFile($event);"/>
                            </div>
                            
                            <div v-if="formStorage.surgicalFolderFiles.value.length > 0" class="relatedImages d-flex flex-wrap justify-content-evenly justify-content-sm-center justify-content-md-between justify-content-xl-center justify-content-xxl-evenly align-content-start align-items-start w-100 px-4 px-md-2 px-lg-1 px-xl-1 px-xxl-4 pt-4">
                                <template v-for="(file, flidx) in formStorage.surgicalFolderFiles.value">
                                    <file :patient="pID" :content="file" :dated="formStorage.surgicalFolderFilesDate.value" :folder="file.folder" :idx="flidx" @delete="deleteFile" @edit="editFile"></file>
                                </template>
                                <div class="addIconContainer mb-2 mb-lg-4 mb-xl-2 mt-xl-4 mt-xxl-0">
                                    <div class="addIcon d-flex justify-content-center align-items-center align-content-center" @click="uploadFilesToFolderExistent()">
                                        <img v-svg-inline :src="a2">
                                    </div>
                                </div>
                                <div class="addIconContainer" v-if="formStorage.surgicalFolderFiles.value.length % 2 == 0">
                                    <div class="addIcon d-flex d-xl-none d-xxl-flex pe-none opacity-0 justify-content-center align-items-center align-content-center" @click="uploadFilesToFolderExistent()">
                                        <img v-svg-inline :src="a2">
                                    </div>
                                </div>
                                
                            </div>
                        </aside>
                    </section>

                </article>

                <!-- ********** EMPIEZA EL ARTICLE 3 QUE ES EL DERECHO************ -->
                <article class="col-xl-4 col-12 ps-lg-5 article-3 mb-5 mb-lg-0 d-flex flex-wrap align-items-start align-content-start">

                    <section class="w-100">
                        
                        <aside class="w-100" :class="editBeforeSign('majorMedicalExpenses') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Gastos Médicos Mayores
                            </p>

                            <selector v-model="formStorage.majorMedicalExpenses.value" :items="fillValues.majorMedicalExpenses">
                            </selector>

                        </aside>

                    </section>

                    <section class="w-100 mt-4" ref="assignedRoom">

                        <aside class="w-100" :class="editBeforeSign('assignedRoom') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Sala asignada
                            </p>

                            <selector v-model="formStorage.assignedRoom.value" :items="fillValues.assignedRoom" keyShow="name" keyValue="idUser" @change="getAvailabilityInSchedule()">
                            </selector>

                        </aside>

                    </section>

                    <section class="w-100 mt-4 d-flex" ref="assignedDate">

                        <aside class="w-50 pe-2">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Fecha de Cirugía
                            </p>

                            <input class="input-date pe-3 input" type="date"
                                placeholder="DD/MM/AAAA" required  :min="this.dateFormated" v-model="formStorage.assignedDate.value" lang="en-CA" @change="getAvailabilityInSchedule()" maxlength="100"/>
                        </aside>

                        <aside class="w-50 ps-2" ref="spacesInDateDuration" :class="formStorage.assignedRoom.value == 0 || formStorage.assignedRoom.value == '' || formStorage.duration.value == '' ? 'pe-none' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Hora de Cirugía
                            </p>

                            <selector :disabling="this.spacesInDateDuration.length > 0 && !this.passDate(this.formStorage.assignedDate.value) ? false : true" v-model="formStorage.assignedTimeInterval.value" :items="spacesInDateDuration" @change="tryBlockSpace()">
                            </selector>
                        </aside>

                    </section>

                    <section class="w-100 mt-4 d-flex">

                        <aside class="w-100" :class="editBeforeSign('equipmentToUse') ? 'open' : ''">

                            <p class="nhb txt-oscuro text-start mb-2">
                                Equipos a utilizar
                            </p>

                            <selector v-model="formStorage.equipmentToUse.value" :items="fillValues.equipmentToUse">
                            </selector>

                        </aside>

                    </section>

                    <section class="w-100 mt-4 d-flex">

                        <aside class="w-100" :class="editBeforeSign('lioModel') ? 'open' : ''">
                            <p class="nhb txt-oscuro text-start mb-2">
                                Modelo de LIO
                            </p>

                            <selector v-model="formStorage.lioModel.value" :items="fillValues.lioModel">
                            </selector>
                        </aside>

                    </section>

                    <section class="w-100 mt-4 d-flex">
                        <aside class="w-100" :class="editBeforeSign('lioPower') ? 'open' : ''">

                            <p class="nhb txt-oscuro text-start mb-2">
                                Poder de LIO
                            </p>
                            <input v-model="formStorage.lioPower.value" class="input-text" type="text"
                                    placeholder="Ingresar valor" required @input="filterText('lioPower')"/>
                        </aside>

                    </section>

                </article>
            </section>

        </div>

        <div class="w-100 d-flex justify-content-end mt-4 mb-3">
            <div class="col-12 d-flex justify-content-end col-md-12 col-xl-4 ps-lg-5">
                <div class="col-6 pe-2" :class="Number(fillValues.sign) <= 0 ? 'pe-none opacity-25' : ''">

                    <button :class="Number(fillValues.sign) >= 1 ? '' : 'pe-none opacity-25'" v-if="formStorage.sign.value == false" class="lockButton mt-2" @click="signRequestNPQ()" >
                        <span class="ps-2">Firmar</span>
                        <svg width="20px" height="20px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <rect width="16" height="16" id="icon-bound" fill="none" />
                            <path d="M12.25,7H12V4c0-1.653-1.347-3-3-3H7C5.347,1,4,2.347,4,4v1h2V4c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v3H3.75 C3.338,7,3,7.338,3,7.75v6.5C3,14.662,3.338,15,3.75,15h8.5c0.412,0,0.75-0.338,0.75-0.75v-6.5C13,7.338,12.662,7,12.25,7z" />
                        </svg>
                    </button>
                    <button :class="Number(fillValues.sign) >= 2 ? '' : 'pe-none opacity-25'" v-else class="lockButton mt-2" @click="signRequestNPQ()" >
                        <span class="ps-2">Desbloquear</span>
                        <svg  width="20px" height="20px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <rect width="16" height="16" id="icon-bound" fill="none" />
                            <path d="M12.25,7H12V4c0-1.653-1.347-3-3-3H7C5.347,1,4,2.347,4,4v3H3.75C3.338,7,3,7.338,3,7.75v6.5C3,14.663,3.338,15,3.75,15h8.5 c0.413,0,0.75-0.337,0.75-0.75v-6.5C13,7.338,12.663,7,12.25,7z M6,4c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v3H6V4z" />
                        </svg>
                    </button>
                   
                </div>
                <div class="col-6 ps-2">
                    <b-selector :class="(isDisabled && !editAccessBeforeSign()) || (formStorage.sign.value && !editAccessBeforeSign()) ? 'blockButton' : ''" v-model="buttonSelected" :items="buttons" @action="catchButtonAction()"></b-selector>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import loader from '@/components/common/loader.vue';
import cModal from '@/components/common/commonModal.vue';
import selector from '@/components/common/selector.vue';
import bSelector from '@/components/common/buttonSelector.vue';
import file from "@/components/file.vue";

export default {
    name: 'NotaQuirurgica',
    components:{
        loader,
        file,
        selector,
        cModal,
        bSelector
    },
    props:{
        con:{
            default:{},
            type: Object
        },
    },
    watch:{
        formStorage: {
            handler: function (val, oldVal) {
                if(this.selectedSurgicalNoteData != null){
                    if(this.selectedSurgicalNoteData.hasOwnProperty('sign')){
                        if(this.selectedSurgicalNoteData.sign == ''){
                            this.editing = true;
                        }
                    }
                }else{
                    this.editing = true;
                }
            },
            deep: true
        }
    },
    data(){
        return{
            a2:require('@/assets/images/expedient/a2.svg'),
            loading:true,

            /*variables get del iframe */
            pID:(this.$route.query.patientID) ? this.$route.query.patientID : null,
            dID:(this.$route.query.doctorID) ? this.$route.query.doctorID : null,
            auth:(this.$route.query.auth) ? this.$route.query.auth : null,
            appointmentID:(this.$route.query.appointmentID) ? this.$route.query.appointmentID : null,

            fillValues:false, //conf
            surgicalNoteHistory:{}, //historial de notas del paciente
           
            lastSelectedSurgicalNote: '',
            selectedSurgicalNote:'', //nombre id de la nota seleccionada
            selectedSurgicalNoteData:null, //datos de la nota seleccionada

            isDisabled:false, //inhabilitador de edición

            formStorage:{

                /* columna 1 */
                items:{type:'array', value:[]},
                eRight:{type:'bool', value:false},
                eLeft:{type:'bool', value:false},
                duration:{type:'string', value:''},
                complexity:{type:'string', value:''},
                comments:{type:'string', value:''},

                /* columna 2 */
                treatingDoctor:{type:'string', value:''},
                surgeon:{type:'string', value:''},
                assistantShip:{type:'string', value:''},
                anesthesiologist:{type:'string', value:''},
                typeOfAnesthesia:{type:'string', value:''},
                /* media */
                surgicalFolderFiles:{type:'array', value:[]},
                surgicalFolderFilesName:{type:'string', value:''},
                surgicalFolderFilesDate:{type:'string', value:''},

                /* columna 3 */
                majorMedicalExpenses:{type:'string', value:''},
                assignedRoom:{type:'string', value:''},
                assignedDate:{type:'string', value:new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })},
                assignedTimeInterval:{type:'string', value:''},
                assignedTimeStart:{type:'string', value:''},
                assignedTimeEnd:{type:'string', value:''},
                equipmentToUse:{type:'string', value:''},
                /* specials */
                lioPower:{type:'string', value:''},
                lioModel:{type:'string', value:''},
                
                sign:{type:'bool', value:false},
                
            },

            editing:false,

            folders:{}, //todos los archivos media del paciente
            formats:['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'], //formatos aceptados para media
            dateFormated : new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }), //hoy en yyyy-mm-dd
            spacesInDateDuration:[], //espacios disponibles en agenda por intervalo de horas:minutos - horas:minutos
            pendingSurgicalNoteBlockPermanent : false, //objeto o false para indicar que hay un bloqueo de espacio activo antes de guarda la nota quirugica

            commonModalIgniter:0,
            commonModalTitle:'Cambiar de Nota Pre Quirúrgica',
            commonModalMess:'Parece que quieres cambiar de Nota Quirúrgica sin haber guardado la información de la nota actual',
            commonModalOptions:[{title:'Cancelar y Regresar', type:'outline', action:'0'}, {title:'Cambiar de Nota Quirúrgica', type:'red', action:'1'}],

            buttonSelected:'Guardar Nota',
            buttons:['Guardar Nota']

        }
    },
    mounted(){
        
        
        window.addEventListener('message', (event) => {
            /* escucha los eventos de la ventana del iframe, retirar y modificar cuando no sea iframe */
            if(typeof event.data == 'object'){
                if(event.data.hasOwnProperty('trigger')){
                    switch(event.data.trigger){
                        case "rechargeImages":
                            this.rechargeImages();
                            break;
                        case "recharge":
                            this.rechargeData();
                            break;
                        case "save": //evento de guardado
                            this.save();
                            break;
                        case "getHeight": //retorno de altura de body en caso de ser iframe y para vista de ventana pequeña
                            window.parent.postMessage({ trigger: 'setHeightIframe', value: document.body.scrollHeight + 'px', id: event.data.id }, '*');
                            break;
                    }
                }
            }
        });

        if(this.pID!=null&&this.auth!=null&&this.appointmentID!=null&this.dID!=null){

            this.con.getSurgicalOptions(this.auth).then((res)=>{ //obtiene la configuracion de las notas quirugicas por default del paciente

                this.fillValues = res.data; //configuracion default
                this.setExtraData();
                this.setDefaultItems();// rellena los items diag y treat para usar v-model en el listado

                this.getFilesInUser().then(()=>{
                    this.formStorage.surgicalFolderFilesName.value = this.fillValues.folder;
                    this.matchFiles();
                    setTimeout(()=>{
                        this.editing = false;
                    }, 1000)
                   
                }).catch((err)=>{console.log(err)}) // obtiene las carpetas del paciente
                
                this.getNoteHistory().then(()=>{
                    for(let dated in this.surgicalNoteHistory){
                        for(let npq in this.surgicalNoteHistory[dated]){
                            if(this.surgicalNoteHistory[dated][npq].hasOwnProperty('surgeryIdAppointment')){
                                if(this.surgicalNoteHistory[dated][npq].surgeryIdAppointment == this.appointmentID){
                                    try{
                                        this.selectedSurgicalNote = this.surgicalNoteHistory[dated][npq].idOnicoModuleSurgicalNote;
                                        this.fillFormStorage();
                                    }catch(err){}
                                }
                            }
                        }
                    }
                })

            }).catch((err)=>{console.log(err)})

        }
    },
    methods:{

        setExtraData(){
            if(this.fillValues.hasOwnProperty('equipmentToUse')){
                this.fillValues.equipmentToUse.push('No Aplica')
            }
            if(this.fillValues.hasOwnProperty('lioModel')){
                this.fillValues.lioModel.push('No Aplica')
            }
            if(this.fillValues.hasOwnProperty('assignedRoom')){
                this.fillValues.assignedRoom.push(
                    {
                        "idUser": "0",
                        "interval": "0",
                        "name": "No Aplica"
                    }
                )
            }
        },

        editAccessBeforeSign(){
            /* revisa si algun control en la lista esta permitido*/
            let access = false;
            for(let i in this.fillValues.controlEnable){
                if(this.fillValues.controlEnable[i] == 1){
                    access = true;
                    break;
                }
            }
            return access
        },

        editBeforeSign(field){
            if(this.fillValues.controlEnable.hasOwnProperty(field)){
                if(this.fillValues.controlEnable[field] == 1){
                    return true
                }
            }
            return false
        },

        rechargeImages(){ // petition normamente hecha por el modulo de imagenes
            this.getFilesInUser().then(()=>{
                this.matchFiles();
            }).catch((err)=>{console.log(err)})
        },

        addItems(){
            if(this.selectedSurgicalNote != ''){ // si ya esta seleccionada una nota, revisar los items existentes y agregar los que no encuentre
                this.NewNote()
            }else{
                this.formStorage.items.value = [];
                this.setDefaultItems();
            }
        },

        rechargeData(){

            this.con.getSurgicalOptions(this.auth).then((res)=>{ //obtiene la configuracion de las notas quirugicas por default del paciente
                this.fillValues = res.data;
                this.addItems();
                this.getFilesInUser().then(()=>{})
            }).catch((err)=>{console.log(err)}) 

        },

        getNoteHistory(){
            return new Promise((resolve, reject) => {
                this.loading = true;
                this.con.getSurgicalData({ //obtiene el historial de notas quirugicas del paciente
                    token:this.auth, 
                    data:{
                        "SurgicalNoteHistory": {
                            "idAppointment": this.appointmentID,
                            "idPatient": this.pID
                        }
                    }
                }).then((res2)=>{
                    this.surgicalNoteHistory = res2.data.SurgicalNoteHistory;
                    this.loading = false; //apaga el cover de load
                    resolve(true)
                }).catch((err)=>{})
            }).catch((err)=>{});
        },

        filterCModalAction(value){
            switch(value){
                case '0':
                    this.selectedSurgicalNote = this.lastSelectedSurgicalNote;
                    break;
                case '1':
                    this.fillFormStorage();
                    break;
                default:
                    break;
            }
        },

       

        catchButtonAction(){
            switch(this.buttonSelected){
                case 'Guardar Nota':
                    if(!this.isDisabled || Object.keys(this.fillValues.controlEnable).length > 0){
                        this.save(false)
                    }
                    break;
                case 'Firmar Nota':
                    if(!this.isDisabled && this.fillValues.sign == 1){
                        this.save(true)
                    }
                    break;
                default:
                    break;
            }
        },

        tryBlockSpace(){
            /* bloquear el espacio date/hora en id de agenda seleccionada*/
            

            if(this.formStorage.assignedTimeInterval.value == ''){
                return false
            }

            /* al seleccionar el intervalo de hora, se debe volver a hacer la peticion de disponibilidad de espacios y si el resultado incluye el intervalo seleccionado, entonces mandar peticion de reserva (si se añade websocket se dejaria de hacer asi) */
            
            this.con.getAvailabilityInSchedule({
                data:{
                    "idDoctor": this.formStorage.assignedRoom.value,
                    "start": this.formStorage.assignedDate.value,
                    "end": this.formStorage.assignedDate.value,
                    "time": Number(this.formStorage.duration.value)
                }, 
                token: this.auth
            }).then((res)=>{
                if(res.code = 200){

                    this.spacesInDateDuration = res.data;

                    if(this.spacesInDateDuration.includes(this.formStorage.assignedTimeInterval.value)){ // si se encuentra el intervalo en la respuesta

                        this.formStorage.assignedTimeStart.value = this.formStorage.assignedDate.value+" "+this.formadTime(this.formStorage.assignedTimeInterval.value)[0];
                        this.formStorage.assignedTimeEnd.value = this.formStorage.assignedDate.value+" "+this.formadTime(this.formStorage.assignedTimeInterval.value)[2];

                        this.pendingSurgicalNoteBlockPermanent = {
                            "idDoctor": this.formStorage.assignedRoom.value,
                            "start": this.formStorage.assignedTimeStart.value,
                            "end": this.formStorage.assignedTimeEnd.value,
                            "status": "busy"
                        }

                        this.con.blockSpaceDate({
                            token:this.auth,
                            data:this.pendingSurgicalNoteBlockPermanent
                        }).then(()=>{

                            this.con.getAvailabilityInSchedule({ // recarga de nuevo los espacios luego de reservar
                                data:{
                                    "idDoctor": this.formStorage.assignedRoom.value,
                                    "start": this.formStorage.assignedDate.value,
                                    "end": this.formStorage.assignedDate.value,
                                    "time": Number(this.formStorage.duration.value)
                                }, 
                                token: this.auth
                            }).then((res)=>{
                                if(res.code = 200){
                                    this.spacesInDateDuration = res.data
                                }
                            }).catch((err)=>{})
                                    
                        }).catch((err)=>{console.log(err)});

                    }else{
                        window.parent.postMessage({ trigger: 'commonMessage', value:'El intervalo seleccionado parece no estar disponible', type: 'error' }, '*');
                        this.formStorage.assignedTimeInterval.value = '';
                    }
                }
                
            }).catch((error) => {
                resolve(false)
            });

          
        },

        freeSpace(){
            /* libera un espacio en la agenda */
            if(this.pendingSurgicalNoteBlockPermanent != false){ //se necesita liberar algo que se ocupo y no se va guardar
                this.pendingSurgicalNoteBlockPermanent.status = 'unBloqued';
                this.con.blockSpaceDate({
                    token:this.auth,
                    data:this.pendingSurgicalNoteBlockPermanent
                }).then(()=>{
                    this.pendingSurgicalNoteBlockPermanent = false;
                }).catch((err)=>{console.log(err)});
            }
        },

        pasteFilterNumber(event) {
            event.preventDefault();
            let filtered = event.clipboardData.getData('text').replace(/\D+/g, '');
            event.target.value += filtered;
            event.target.dispatchEvent(new Event('input'));
        },

        onlyNumbers(e){
            let keyCode = (e.keyCode ? e.keyCode : e.which);
            if (!e.ctrlKey){
                if (!/\d/.test(e.key) && keyCode != 8){
                    e.preventDefault();
                }
            }else{
                if(e.key != 'v'){
                    e.preventDefault();
                }
            }
        },

        filterText(name){
            //filtra texto para evitar caracteres no deseados
            let prevText = this.formStorage[name].value;
            prevText = prevText.replace(/<[^>]*>/g, '');
            prevText = prevText.replace(/[\\\'\\\"\\=]/g, '');
            this.formStorage[name].value = prevText;
        },

        formadTime(timeSep){
            /* cuando son hh:mm:ss - hh:mm:ss*/
            return timeSep.split(" ")
        },

        setDefaultItems(){
            /*items y diags del paciente en este momento*/
            let objectHelper = {};
            for(let i in this.fillValues.treat){
                objectHelper = this.fillValues.treat[i];
                objectHelper.type = 'treat';
                objectHelper.status = 0;
                this.formStorage.items.value.push(objectHelper)
            }
            for(let i in this.fillValues.diag){
                objectHelper = this.fillValues.diag[i];
                objectHelper.type = 'diag';
                objectHelper.status = 0;
                this.formStorage.items.value.push(objectHelper)
            }
            return true
        },

        removeErrorsTimely(){
            setTimeout(()=>{
                document.querySelectorAll('.error').forEach((elm)=>{
                    elm.classList.remove('error')         
                });
            }, 2000)
        },

        requiredSignFiels(){
            let result = true;
            let diagSelected = false;
            let treatSelected = false;
            
            if(this.formStorage.assignedRoom.value == 0){
                this.$refs.assignedRoom.classList.add('error');
                result = false;
            }
            
            if(this.formStorage.assignedDate.value == '' || this.formStorage.assignedDate.value == null){
                this.$refs.assignedDate.classList.add('error')
                result = false;
            }
            if(this.formStorage.assignedTimeStart.value == '' || this.formStorage.assignedTimeStart.value == null || this.formStorage.assignedTimeEnd.value == '' || this.formStorage.assignedTimeEnd.value == null){
                this.$refs.spacesInDateDuration.classList.add('error')
                result = false;
            }
            if(this.formStorage.eRight.value == false && this.formStorage.eLeft.value == false){
                this.$refs.eyeSwitchs.classList.add('error')
                result = false;
            }
            if(this.formStorage.surgeon.value == '' || this.formStorage.surgeon.value == null){
                this.$refs.surgeon.classList.add('error')
                result = false;
            }
            if(this.formStorage.assistantShip.value == '' || this.formStorage.assistantShip.value == null){
                this.$refs.assistantShip.classList.add('error')
                result = false;
            }
            if(this.formStorage.typeOfAnesthesia.value == '' || this.formStorage.typeOfAnesthesia.value == null){
                this.$refs.typeOfAnesthesia.classList.add('error')
                result = false;
            }
            if(this.formStorage.complexity.value == '' || this.formStorage.complexity.value == null){
                this.$refs.complexity.classList.add('error')
                result = false;
            }
            if(this.formStorage.duration.value == '' || this.formStorage.duration.value == null){
                this.$refs.duration.classList.add('error')
                result = false;
            }
            for(let i in this.formStorage.items.value){
                if(this.formStorage.items.value[i].type == 'diag'){
                    if(this.formStorage.items.value[i].status == 1 || this.formStorage.items.value[i].status == true){
                        diagSelected = true; 
                    }
                }
            }
            for(let i in this.formStorage.items.value){
                if(this.formStorage.items.value[i].type == 'treat'){
                    if(this.formStorage.items.value[i].status == 1 || this.formStorage.items.value[i].status == true){
                        treatSelected = true; 
                    }
                }
            }

            if(!treatSelected){
                this.$refs.treats.classList.add('error');
                result = false;
            }
            if(!diagSelected){
                this.$refs.diags.classList.add('error');
                result = false;
            }

            this.removeErrorsTimely();


            return result && diagSelected && treatSelected
        },

        requiredSaveFiels(){
            let result = true;
            let diagSelected = false;
            let treatSelected = false;
            if(this.formStorage.assignedDate.value == '' || this.formStorage.assignedDate.value == null){
                this.$refs.assignedDate.classList.add('error')
                result = false;
            }
            if(this.formStorage.assignedRoom.value != 0){
                if(this.formStorage.duration.value == '' || this.formStorage.duration.value == null){
                    this.$refs.duration.classList.add('error');
                    result = false;
                }
            }else{
                this.formStorage.duration.value = "";
            }
            
            if(this.formStorage.assignedRoom.value == '' || this.formStorage.assignedRoom.value == null){
                this.$refs.assignedRoom.classList.add('error');
                result = false;
            }

            if(this.formStorage.assignedRoom.value != 0){
                if(this.formStorage.assignedTimeStart.value == '' || this.formStorage.assignedTimeStart.value == null || this.formStorage.assignedTimeEnd.value == '' || this.formStorage.assignedTimeEnd.value == null){
                    this.$refs.spacesInDateDuration.classList.add('error')
                    result = false;
                }
            }else{
                this.formStorage.assignedTimeStart.value = '00:00';
                this.formStorage.assignedTimeEnd.value = '00:00';
            }
            
            if(this.formStorage.eRight.value == false && this.formStorage.eLeft.value == false){
                this.$refs.eyeSwitchs.classList.add('error')
                result = false;
            }

            for(let i in this.formStorage.items.value){
                if(this.formStorage.items.value[i].type == 'diag'){
                    if(this.formStorage.items.value[i].status == 1 || this.formStorage.items.value[i].status == true){
                        diagSelected = true; 
                    }
                }
            }
            for(let i in this.formStorage.items.value){
                if(this.formStorage.items.value[i].type == 'treat'){
                    if(this.formStorage.items.value[i].status == 1 || this.formStorage.items.value[i].status == true){
                        treatSelected = true; 
                    }
                }
            }

            if(!treatSelected){
                this.$refs.treats.classList.add('error');
                result = false;
            }
            if(!diagSelected){
                this.$refs.diags.classList.add('error');
                result = false;
            }

            this.removeErrorsTimely();


            return result && diagSelected
        },

        signRequestNPQ(){
            if(this.formStorage.sign.value){
                this.save(true, true) //desfirmar
            }else{
                this.save(true) //firmar
            }
        },

        save(sign=false, unsign=false){
            /* guarda la nota quirugica o la actualiza dependiendo si existe la variable idOnicoModuleSurgicalNote como selectedSurgicalNote */
            
            if(sign){
                if(!this.requiredSignFiels()){
                    window.parent.postMessage({ trigger: 'commonMessage', value:'Faltan Campos para poder Firmar', type: 'error' }, '*');
                    return false
                }
            }else{
                if(!this.requiredSaveFiels()){
                    window.parent.postMessage({ trigger: 'commonMessage', value:'Faltan Campos para poder Guardar', type: 'error' }, '*');
                    return false
                }
            }

            let treats = [];
            let diags = [];
            let signing = false; //lo que llevara para firmar o no el requestbody

            for(let i in this.formStorage.items.value){
                if(this.formStorage.items.value[i].type == 'treat'){
                    treats.push(this.formStorage.items.value[i]);
                    continue;
                }
                if(this.formStorage.items.value[i].type == 'diag'){
                    diags.push(this.formStorage.items.value[i]);
                    continue;
                }
            }

            if(unsign){ //desfirmar si se solicita
                signing = false;
            }else if((this.formStorage.sign.value || sign) && !unsign){ //firmar si ya esta firmado y solo se guarda o si solicita firmar con sign
                signing = true;
            }

            let dataSurgical = {
                SurgicalNote:{
                    "idAppointment": this.appointmentID,
                    "idPatient": this.pID,
                    "idOnicoModuleSurgicalNote": this.selectedSurgicalNote, /* determina si es nueva o update */
                    "sign":Number(signing),

                    "treat":treats,
                    "diag":diags,
                    "eye-left1": Number(this.formStorage.eLeft.value),
                    "eye-rigth1": Number(this.formStorage.eRight.value),
                    "time": this.formStorage.duration.value,
                    "level": this.formStorage.complexity.value,
                    "commit": this.formStorage.comments.value,
                    
                    "doctor": this.formStorage.treatingDoctor.value,
                    "surgeon": this.formStorage.surgeon.value,
                    "ayudantia": this.formStorage.assistantShip.value,
                    "anesthesiologist": this.formStorage.anesthesiologist.value,
                    "type": this.formStorage.typeOfAnesthesia.value,
                    
                    "majorMedicalExpenses": this.formStorage.majorMedicalExpenses.value,
                    "assignedRoom": this.formStorage.assignedRoom.value,
                    "date": this.formStorage.assignedDate.value,
                    "start":  this.formStorage.assignedTimeStart.value,
                    "end":  this.formStorage.assignedTimeEnd.value,
                    "equipamentToUse": this.formStorage.equipmentToUse.value,
                    "lio": this.formStorage.lioPower.value,
                    "model": this.formStorage.lioModel.value,

                    "preoperatorio": "na",
                    "result": "na",
                    "supplies": "na",
                }
            }

            this.loading = true;
            this.con.saveSurgicalNote({token:this.auth,data:dataSurgical}).then((res)=>{
                window.parent.postMessage({ trigger: 'saveMessage', value:'', id: '' }, '*');
                if(res.code == 200){
                    this.pendingSurgicalNoteBlockPermanent = false; //liberacion de pendiente de bloqueo permanente de espacio, debido a que ya se guarda la notaprequirurgica
                    this.loading=false;
                    this.getNoteHistory().then(()=>{
                        this.selectedSurgicalNote = res.data[0];
                        this.lastSelectedSurgicalNote = this.selectedSurgicalNote;
                        this.editing = false;
                        if(signing){
                            this.formStorage.sign.value = Boolean(signing);
                            this.isDisabled = true;
                        }else{
                            this.formStorage.sign.value = Boolean(signing);
                            this.isDisabled = false;
                        }
                    })
                }
            }).catch((err)=>{})
        },

        resetFormStorage(){
            /* reset para los campos v-model y otros valores determinantes */
            for(let i in this.formStorage){

                if(this.formStorage[i].type == 'string'){
                    this.formStorage[i].value = "";
                    continue;
                }
                if(this.formStorage[i].type == 'bool'){
                    this.formStorage[i].value = false;
                    continue;
                }
                if(this.formStorage[i].type == 'array'){
                    this.formStorage[i].value = [];
                    continue;
                }
            }
            this.isDisabled = false; //inhabilitador de edicion de campos
            this.spacesInDateDuration=[]; // lista de horas disponibles
            this.formStorage.assignedDate.value = this.dateFormated; // fecha para acciones con archivos, como subir
            this.formStorage.surgicalFolderFilesName.value = this.fillValues.folder; // nombre del folder para acciones con archivos, como subir
            this.setDefaultItems(); // tratamientos y diagnosticos por default asignados al pacientes, obtenido de get->conf
            this.matchFiles(); // encuentra archivos si existe para en hoy para notas quirugicas con el nombre folder de get->conf

            this.freeSpace(); //liberar espacio ocupado si no se guardo la nota
            setTimeout(()=>{
                this.editing = false;
            }, 1000)
        },

        NewNote(){
            /* prepara todo para crear nueva nota */
            this.selectedSurgicalNote = '';
            this.selectedSurgicalNoteData = null;
            this.resetFormStorage();
        },

        checkEditField(){
            if(this.editing == true){
                this.commonModalIgniter++;
            }else{
                this.fillFormStorage();
            }
        },

        fillFormStorage(){
            /* rellena los campos v-model para seleccionar todo lo obtenido por una nota existente desde selector superior derecho*/
            this.loading = true;
            this.formStorage.items.value = [];
            this.setDefaultItems();

            this.lastSelectedSurgicalNote = this.selectedSurgicalNote;
            this.freeSpace(); //liberar espacio ocupado si no se guardo la nota

            if(this.selectedSurgicalNote != ''){

                this.con.getSurgicalData({
                    token:this.auth, 
                    data:{
                        "SurgicalNote": {
                            "idAppointment": this.selectedSurgicalNote,
                            "idPatient": this.pID
                        }
                    }
                }).then((res2)=>{

                    this.isDisabled = false;
                    this.selectedSurgicalNoteData = res2.data.SurgicalNote[0];

                    /* columna 1 */

                    // ciclo de items diag y treat
                    for(let i in this.formStorage.items.value){ //poner todos los items a false
                        this.formStorage.items.value[i].status = false;
                    }
                    let founded = false;
                    for(let i in this.selectedSurgicalNoteData.items){ //activar solo los disponibles
                        for(let j in this.formStorage.items.value){ 
                            if(this.formStorage.items.value[j].cieCode == this.selectedSurgicalNoteData.items[i].cieCode){
                                founded = true;
                                this.formStorage.items.value[j].status = Boolean(Number(this.selectedSurgicalNoteData.items[i].status));
                            }
                        }
                        if(!founded){ // si no lo encontro en la lista de configuracion actual lo introduce directamente (talvez se elimino desde exploracion)
                            this.selectedSurgicalNoteData.items[i].status = Boolean(Number(this.selectedSurgicalNoteData.items[i].status));
                            this.formStorage.items.value.push(this.selectedSurgicalNoteData.items[i])
                        }
                        founded = false;
                    }
                    this.selectedSurgicalNoteData.items; //* items diag y treat
                    for(let i in this.formStorage.items.value){ //convertir 0 y 1 a boolean
                        this.formStorage.items.value[i].status = Boolean(Number(this.formStorage.items.value[i].status))
                    }
                    //-------//
                    
                    this.formStorage.eLeft.value = Boolean(Number(this.selectedSurgicalNoteData['eye-left1']));
                    this.formStorage.eRight.value = Boolean(Number(this.selectedSurgicalNoteData['eye-rigth1']));
                    this.formStorage.duration.value = this.selectedSurgicalNoteData.time;
                    this.formStorage.complexity.value = this.selectedSurgicalNoteData.level;
                    this.formStorage.comments.value = this.selectedSurgicalNoteData.commit;

                    /* columna 2 */
                    this.formStorage.treatingDoctor.value = this.selectedSurgicalNoteData.doctor;
                    this.formStorage.surgeon.value = this.selectedSurgicalNoteData.surgeon;
                    this.formStorage.assistantShip.value = this.selectedSurgicalNoteData.ayudantia;
                    this.formStorage.anesthesiologist.value = this.selectedSurgicalNoteData.anesthesiologist;
                    this.formStorage.typeOfAnesthesia.value = this.selectedSurgicalNoteData.type;
                    this.formStorage.surgicalFolderFilesName.value = this.selectedSurgicalNoteData.folder;
                    
                    this.getFilesInUser().then(()=>{
                        this.matchFiles(); //encuentra folder de la npq y datos necesarios
                    })
                    
                    /* columna 3*/
                    this.formStorage.majorMedicalExpenses.value = this.selectedSurgicalNoteData.majorMedicalExpenses;
                    this.formStorage.assignedRoom.value = this.selectedSurgicalNoteData.assignedRoom;
                    this.formStorage.assignedDate.value =  this.selectedSurgicalNoteData.date;


                    if(this.selectedSurgicalNoteData.hasOwnProperty('appointment')){
                        this.formStorage.assignedTimeStart.value =  this.selectedSurgicalNoteData.appointment.start;
                        this.formStorage.assignedTimeEnd.value =  this.selectedSurgicalNoteData.appointment.end;
                        this.formStorage.assignedTimeInterval.value =  this.selectedSurgicalNoteData.appointment.start.split(' ')[1] + ' - ' + this.selectedSurgicalNoteData.appointment.end.split(' ')[1];
                    }else{
                        this.formStorage.assignedTimeStart.value =  '';
                        this.formStorage.assignedTimeEnd.value =  '';
                        this.formStorage.assignedTimeInterval.value =  '';
                    }
                    
                    this.formStorage.equipmentToUse.value = this.selectedSurgicalNoteData.equipamentToUse;
                    this.formStorage.lioPower.value = this.selectedSurgicalNoteData.lio;
                    this.formStorage.lioModel.value = this.selectedSurgicalNoteData.model;
                    
                    this.formStorage.sign.value = this.selectedSurgicalNoteData.sign != '';

                    /*if (this.passDate(this.formStorage.assignedDate.value)) { // inhabilitar ediciones si la fecha de la npq ya pasó
                        this.isDisabled = true;
                    }else{
                        this.isDisabled = false;
                    }*/

                    if(!isNaN(this.formStorage.assignedRoom.value) && this.formStorage.assignedTimeInterval.value != ''){ //verifica que la sala sea un numero y no el nombre
                        this.getAvailabilityInSchedule(false).then(()=>{
                            this.spacesInDateDuration.push(this.formStorage.assignedTimeInterval.value) //push del intervalo actual porque el enpoint de disponibilidad no deberia traerlo
                        }).catch((err)=>{}); //obtiene las horas disponibles para en caso de reasignar la cita
                    }else{
                        this.formStorage.assignedRoom.value = '';
                        this.formStorage.assignedTimeInterval.value = '';
                    }

                    if(this.selectedSurgicalNoteData != null){
                        if(this.selectedSurgicalNoteData.hasOwnProperty('sign')){
                            if(this.selectedSurgicalNoteData.sign != '' && this.selectedSurgicalNoteData.sign != '0' ){
                                this.isDisabled = true;
                            }else{
                                this.isDisabled = false;
                            }
                        }
                    }

                    setTimeout(()=>{
                        this.editing = false;
                        this.loading = false;
                    }, 250)
                    

                }).catch((err)=>{console.log(err)});
            }
        },

        matchFiles(){
            /* revisar en carpetas de usuario si existe la carpeta y posicionar los archivos en la variable para listarlos*/
            for(let date in this.folders){
                for(let folderName in this.folders[date]){
                    if(folderName == this.formStorage.surgicalFolderFilesName.value){
                        this.formStorage.surgicalFolderFiles.value = this.folders[date][folderName]['files']; //archivos encontrados
                        this.formStorage.surgicalFolderFilesDate.value = date; //fecha del folder para evitar crear nuevo folder en caso de actualizar archivos o editar los mismos
                        return true;
                    }
                }
            }
            this.formStorage.surgicalFolderFilesDate.value = this.dateFormated; // fecha de hoy en caso de encontrar un folder creado para la nota pq seleccionada o si es nueva npq
            this.formStorage.surgicalFolderFiles.value = [];
            return false;
        },

        getFilesInUser(){
            /*consulta enpoint para obtener los archivos del usuario */

            return new Promise((resolve, reject) =>{
                let requestBody = {
                    "idPatient": this.pID,
                    "orderBy": "date",
                    "groupBy": "folder"
                };

                this.con.getFilesInUser({token: this.auth, data:requestBody}).then((response) => {
                    if(response.data != null && response.code == 200){
                        this.folders = response.data; //archivos totales del paciente
                        resolve(true)
                    }
                }).catch((error) => {
                    console.log(error)
                });
            })
            
        },

        getAvailabilityInSchedule(resetSelectedInterval=true){
            /* obtiene el arreglo de horas disponibles, determinados por la duracion, la sala y la fecha */

            if(this.formStorage.assignedRoom.value == 0){
                this.formStorage.assignedTimeInterval.value = '';
                this.formStorage.duration.value = "";
                return false;
            }

            return new Promise((resolve)=>{

                if(this.formStorage.assignedRoom.value == '' || this.formStorage.duration.value == '' || this.formStorage.assignedDate.value == '' || this.formStorage.assignedDate.value == undefined){
                    resolve(false)
                    return false;
                }

                if(resetSelectedInterval){ // si requiere resetear la variable de intervalo asignado
                    this.formStorage.assignedTimeInterval.value = ''
                }
                this.spacesInDateDuration =  []; //* vaciado de intervalos antes de consultar*/

                this.con.getAvailabilityInSchedule({
                    data:{
                        "idDoctor": this.formStorage.assignedRoom.value,
                        "start": this.formStorage.assignedDate.value,
                        "end": this.formStorage.assignedDate.value,
                        "time": Number(this.formStorage.duration.value)
                    }, 
                    token: this.auth
                }).then((res)=>{
                    this.spacesInDateDuration = res.data
                    resolve(true);
                }).catch((error) => {
                    resolve(false)
                });
            })
            
        },

        passDate(date){
            /* comprueba si una fecha es menor que hoy */
            let now = new Date();
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0);
            let [year, month, day] = date.split("-");
            let dateTransformed = new Date(year, month-1, day, 12, 0, 0);
            return dateTransformed < now;
        },

        uploadFilesToFolderExistent(){
            this.$refs.fileDragSurgical.click();
        },

        uploadOneFile(file){
            /* sube los archivos multimedia uno por uno a la carpeta correspondiente (mostrar cargador de archivos individuales en futuro update)*/ 

            return new Promise((resolve)=>{
                this.loading = true;
                const formData = new FormData();
                let dateToUpload = this.dateFormated;

                if(this.selectedSurgicalNote != ''){ 
                    /*determina la fecha a aplicar debido a que aun teniendo el mismo nombre el folder, si la fecha es diferente se creará una nueva y no sera una actualización si hay un anota seleccionada */
                    dateToUpload = this.formStorage.surgicalFolderFilesDate.value;
                    // en caso de que la nota no tenga un folder creado esta variable tendra asignada el dia de hoy formateado en yyyy-mm-dd
                }

                formData.append('files[]', file);
                formData.append('idPatient', this.pID);
                formData.append('idDoctor', this.dID);
                formData.append('idAppointment', '0');
                formData.append('date', dateToUpload);
                formData.append('folder', this.formStorage.surgicalFolderFilesName.value); 
                // tendrá el nombre del folder si se encontró uno al seleccionar una nota pq o si no tendrá el default que devuelve conf

                this.con.postFilesInUser({
                    token:this.auth, 
                    data:formData
                }).then((res)=>{
                    this.loading = false;
                    if(res.data != null && res.code == 200){

                        if(!this.folders.hasOwnProperty(dateToUpload)){
                            this.folders[dateToUpload] = {};
                        }
                        if(!this.folders[dateToUpload].hasOwnProperty(this.formStorage.surgicalFolderFilesName.value)){
                            this.folders[dateToUpload][this.formStorage.surgicalFolderFilesName.value] = {};
                        }
                        if(!this.folders[dateToUpload][this.formStorage.surgicalFolderFilesName.value].hasOwnProperty('files')){
                            this.folders[dateToUpload][this.formStorage.surgicalFolderFilesName.value].files = [];
                        }
                        //this.folders[dateToUpload][this.formStorage.surgicalFolderFilesName.value].files.unshift(res.data[dateToUpload].files[0]);

                        this.formStorage.surgicalFolderFiles.value.unshift(res.data[dateToUpload].files[0]) // solo hace push de un item debido que retorna solo que se sube
                    }
                    resolve(true)
                }).catch((err)=>{this.loading = false;resolve(true)})
            }).catch((err)=>{})
        },


        async chargeFile(e){
            /* carga los archivos multimedia en el input select a la carpeta correspondiente */
            this.loading = true;
            e.preventDefault();
            const input = this.$refs.fileDragSurgical;

            for(let i=0; i<input.files.length; i++){
                if(this.formats.includes(input.files[i].type)){ //comprueba la validez de los formatos

                    await this.uploadOneFile(input.files[i]).then(async (res)=>{

                        if((i+1) == input.files.length){ // mandar evento para actualizar modulo de imagenes si es el ultimo archivo en subir
                            window.parent.postMessage({ trigger: 'refreshImagesImagingModule' }, '*');
                            this.loading = false;
                        }

                    });
                    
                }
            } 
        },

        editFile(idFile, data){
            /* editar el nombre de un archivo por su id unico */
            let requestBody = {
				"fileNameUser": String(data)
			};
            this.con.editFileName({token: this.auth, data:requestBody, id:idFile}).then((response) => {
				if(response.data != null && response.code == 200){
                    window.parent.postMessage({ trigger: 'refreshImagesImagingModule' }, '*');
                }
			}).catch((error) => {
				console.log(error)
			})
        },

        deleteFile(idFile, dated, folder, idx){
            /* elimina un archivo por su id unico y actualiza los arreglos correspondientes */
            if(folder == '' || folder == null){
                folder = dated;
            }
            
            this.con.deleteFile({token: this.auth, id:idFile}).then((response) => {
				if(response.data != null && response.code == 200){
                    if(this.folders.hasOwnProperty(dated)){
                        if(this.folders[dated].hasOwnProperty(folder)){
                            this.folders[dated][folder].files.splice(idx, 1); // elimiar del arreglo general
                            this.$forceUpdate();
                            this.matchFiles();
                            window.parent.postMessage({ trigger: 'refreshImagesImagingModule' }, '*'); //mandar evento para actualizar modulo de imagenes si es el ultimo archivo en subir
                            return true
                        }
                    }
                    this.formStorage.surgicalFolderFiles.value.splice(idx, 1); //elimina del arreglo del listado para la npq seleccionada
                    this.$forceUpdate();
                    window.parent.postMessage({ trigger: 'refreshImagesImagingModule' }, '*'); //mandar evento para actualizar modulo de imagenes si es el ultimo archivo en subir
                }
			}).catch((error) => {
				console.log(error)
			})

        },
    }
}
</script>
  
<style scoped lang="css">

.SurgicalNote{
    min-height: 100%;
    overflow-y: auto;
    
}

.sections.blocked aside:not(.open) > *{
    pointer-events: none;
    cursor: not-allowed;
    user-select: none;
	-webkit-user-select: none;
}

.sections.blocked aside:not(.open){
    cursor: not-allowed;
    user-select: none;
	-webkit-user-select: none;
}

.txt-oscuro {
    color: #16181c;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.color-select {
    color: red;
}

.SurgicalNote ::placeholder, .valuePlaceholder {
    color: #cccccc !important;
}

.item:not(:first-of-type){
    margin-top: 10px;
}

.parrafo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.botons p {
    margin: 0;
}

.botons .botons-extraccion {
    width: 80%;
}

/* Estilos del botón switch */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    flex-shrink: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.error .slider, .error textarea, .error .input-text{
    animation: border-animation 0.5s infinite linear;
}
.error .slider::before{
    animation: bgcolor-animation 0.5s infinite linear;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--primary);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--nonagenary);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* -- */
/* estilo de inputs text */

.SurgicalNote .input-text {
    background-color: white;
    width: 100%;
    border-radius: 11px;
    height: 43px;
    border: 1px #cccccc solid;
    outline: none;
    padding-left: 15px;
}

.SurgicalNote .input-date {
    background-color: white;
    width: 100%;
    border-radius: 11px;
    height: 43px;
    border: 1px #cccccc solid;
    outline: none;
    padding-left: 15px;
}

.SurgicalNote .input-date[type=date]:invalid::-webkit-datetime-edit {
    color: #ccc;
}

.SurgicalNote textarea {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    border: 1px #cccccc solid;
    resize: none;
    padding: 10px;
    font-size: 16px;
}

.SurgicalNote .custom-file {
    position: relative;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #D5D5D5;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.SurgicalNote .custom-file-label {
    font-size: 18px;
    color: white;
    cursor: pointer;
}

#fileDragSurgical {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.toBottom {
    margin-top: auto;
}
/* -- */
/* buttons */
.addButton{
    background-color:var(--primary);
    border-radius: 11px;
    color: var(--white);
    display: flex;
    font-size: 15px;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    text-align: left;
    padding: 5px 10px;
    height: 45px;
    line-height: 1;
    outline: none;
    width: 100%;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.addButton svg{
    height: 25px;
    width: 25px;
    pointer-events: none;
}

.addButton svg path{
    fill:var(--white)
}

.addButton:hover{
    opacity: 0.9;
}

.lockButton{
    background-color:var(--white);
    border-radius: 11px;
    color: var(--primary);
    display: flex;
    font-size: 15px;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    text-align: left;
    font-weight: 500;
    padding: 5px 10px;
    height: 45px;
    line-height: 1;
    outline: none;
    width: 100%;
    border: 3px solid var(--primary);
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.lockButton svg{
    height: 25px;
    width: 25px;
    pointer-events: none;
}

.lockButton svg path{
    fill:var(--primary)
}

.lockButton:hover svg path{
    fill:var(--white)
}


.lockButton:hover{
    background-color:var(--primary);
    color: var(--white);
    opacity: 0.9;
}

.blockButton{
    opacity: 0.5;
    pointer-events: none;
}

/* images */

.addIconContainer{
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: start;
    align-content: start;
}
.addIcon{
    width: 140px;
    height: 140px;
    border: 3px solid #D5D5D5;
    border-radius: 40px;
    flex: 0 0 140px;
    cursor: pointer;
}
.addIcon svg{
    width: 50%;
    height: 50%;
    object-fit: contain; 
    pointer-events: none;
}
::v-deep(.addIcon path) {
    fill:#D5D5D5;
}
.addIcon:hover{
    transform: scale(1.05);
    opacity: 0.7;
    border: 3px solid var(--primary);
}
::v-deep(.addIcon:hover path){
    fill:var(--primary);
}

::v-deep(.error .selectSimulator){
    animation: border-animation 0.5s infinite linear;
}

@keyframes border-animation {
    0% {
        border-color: red;
    }
    50% {
        border-color: #cccccc;
    }
    100% {
        border-color: red;
    }
}

@keyframes bgcolor-animation {
    0% {
        background-color: red;
    }
    50% {
        background-color: #cccccc;
    }
    100% {
        background-color: red;
    }
}

</style>
  