<template lang="html">
    <div class="modal-wrap d-flex justify-content-center align-items-start pt-4 pt-lg-0 align-content-start align-content-lg-center align-items-lg-center">
        
        <div id="folderModal" class="bg-white px-5 d-flex flex-column justify-content-start align-items-start align-content-start flex-nowrap">
            <div class="w-100 headModal d-flex flex-row mt-4 align-items-center">
                <div class="flex-fill d-flex flex-nowrap align-items-center">
                </div>
                <div class="iconContainer close pt-2" @click="closeModal()"><img v-svg-inline :src="a5"></div>
            </div>

            <div class="col-12 mainModal d-flex flex-fill justify-content-center mt-1 px-2 align-items-start align-content-start">

                <div class="col-12 h-100 d-flex flex-column flex-nowrap justify-content-start align-items-center align-content-center pb-4">

                    <div class="col-12 col-lg-11 px-lg-3 px-0 text-s-1 d-flex justify-content-start fw-bold">
                        Nuevo recurso compartido
                    </div>

                    <div class="col-12 col-lg-11 px-lg-3 px-0 d-flex justify-content-center mt-0 fw-bold mt-lg-5" style="margin-top:auto">
                        <div :style="{ marginTop: wordsMatched.length <= 0 ? '40px' : '40px' }" class="inputFolderContainer col-10 position-relative d-flex flex-wrap align-items-center align-content-center">
                            <input @input="matchTags()" :class="w>1300 ? 'inset' : 'outset'" type="text" placeholder="Estudio de laboratorio" maxlength="150" ref="folderName" v-model="folderName">
                            <button :disable="(folderSelected || (mode == 'create' && folderName.length >= 3)) ? 'false' : 'true'" :class="w>1300 ? 'inset' : 'outset'" @click="next()">Guardar</button>
                            <span :class="w>1300 ? 'inset' : 'outset'"><img v-svg-inline :src="a23"></span>
                        </div>
                    </div>


                    <div class="autocompleteTags col-10 col-lg-9 pb-lg-5 d-flex flex-wrap flex-lg-nowrap px-lg-3 px-4 align-items-start align-content-start pt-2 pt-xl-5"  v-if="wordsMatched.length <= 0 || mode == 'create'" >
                        <div class="col-lg-6 col-12 d-flex justify-content-start mt-4 mt-lg-0 justify-content-lg-center align-items-center align-content-center modeSelector" @click="setMode('search')">
                            <input type="radio" name="search" class="me-3" :checked="mode == 'search' ? true : false "> <label for="search">Buscar en catálogo</label>
                        </div>
                        <div class="col-lg-6 col-12 d-flex justify-content-start mt-4 mt-lg-0 justify-content-lg-center align-items-center align-content-center modeSelector" @click="setMode('create')">
                            <input type="radio" name="create" class="me-3" :checked="mode == 'create' ? true : false "> <label for="create">Ingreso manual</label>
                        </div>
                    </div>

                    <div class="autocompleteTags col-10 col-lg-9 d-flex flex-wrap px-lg-3 px-4 align-items-start align-content-start pt-4 pt-xl-5 pb-5"   v-else-if="wordsMatched.length > 0 && mode == 'search'" >
                        <div v-for="(matched, mtx) in wordsMatched" :key="mtx" class="matchTag col-12 col-lg-auto pe-3 ps-2 mb-3 mb-xl-3 me-1 ms-1 me-lg-3 ms-lg-0 d-flex justify-content-center align-items-center" @click="setValue(matched, $event)">
                            <img v-svg-inline :src="a24" class="pe-none">
                            <div class="flex-fill d-flex pe-none h-auto align-items-start align-content-start matchLabel">
                                {{matched}}
                            </div>
                        </div>
                    </div>
        
                </div>

            </div>
        </div>

    </div>
</template>
<script>

export default {
    name:"folderModal",
    emits:['close', 'saveTemp'],
    props:{
        wizard:false,
        tags:{}
    },
    data(){
        return{
            w:window.innerWidth,
            h:window.innerHeight,
            a5:require('@/assets/images/expedient/a5.svg'),
            a23:require('@/assets/images/expedient/a23.svg'),
            a24:require('@/assets/images/expedient/a24.svg'),
            folderName:"",
            folderSelected:false,
            wordsMatched:[],
            mode:'search'
        }
    },
    mounted(){
        //this.$refs.folderName.focus();
        window.onresize = () => {
            this.w =  window.innerWidth;
            this.h = window.innerHeight;
        }
    },
    methods:{
        setMode(mode){
            this.mode = mode;
        },
        closeModal(){
            this.$emit('closeModal', 'folder')
        },
        next(){
            this.$emit('saveTemp', {folder:this.folderName, date:(new Date).toLocaleString("en-CA", { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' })}, this.wizard, 'upload', 'folder')
        },
        setValue(word, e){
            document.querySelectorAll('.matchTag').forEach((tag)=>{
                tag.classList.remove('selected')
            })
            e.target.classList.add('selected')
            this.folderName = word;
            this.folderSelected = true;
        },
        matchTags(){
            this.folderSelected = false;
            this.wordsMatched = [];
            let h1 = "";
            let h2 = "";

            document.querySelectorAll('.matchTag').forEach((tag)=>{
                tag.classList.remove('selected')
            })

            if(this.folderName.length > 0){
                for (let i in this.tags) {
                    for(let j in this.tags[i]){
                        h1 = ((this.folderName).toLowerCase()).normalize("NFKD").replace(/[\u0300-\u036F]/g, "");
                        h2 = ((this.tags[i][j]).toLowerCase()).normalize("NFKD").replace(/[\u0300-\u036F]/g, "");

                        if (h2.includes(h1)) {
                            this.wordsMatched.push(this.tags[i][j]);
                        }
                    }
                }
            }
        }
    }
}
</script>
<style lang="css">
    #folderModal{
        position: relative;
        width: 90%;
        height: 90%;
        max-height: 850px;
        z-index: 1;
        overflow-y: auto;
        filter: drop-shadow(0px 12px 23px rgba(0,0,0,0.16 ));
        border-radius: 44px;
    }

    @media (max-height: 700px){
        #folderModal{
            max-height: 650px;
        }
    }

    .headModal{
        height: 50px;
        position: sticky;
        top: 1.5rem;
    }
    .mainModal{
        height: calc(70% - 50px);
    }
    .footModal{
        height: 30%;
    }

    .inputFolderContainer{
        height: auto;
        transition: margin 0.5s;
    }

    .inputFolderContainer input.inset{
        padding-right: 300px;
        height: 141px !important;
        padding-left: 140px !important;
        font-size: 42px !important;
    }

    .inputFolderContainer input{
        width: 100%;
        height: 100px;
        font-size: 28px;
        padding-left: 60px;
        color: #444;
        font-weight: bold;
        border:6px solid #d5d5d5;
        border-radius: 19px;
        outline:none;
    }

    .inputFolderContainer input:focus{
        border:6px solid #f89893;
    }

    .inputFolderContainer input::placeholder {
        color: #D5D5D5;
        opacity: 1; 
    }

    .inputFolderContainer input::-ms-input-placeholder {
        color: #D5D5D5;
    }

     .inputFolderContainer button:hover{
        opacity: 0.8;
    }

    .inputFolderContainer button{
        border-radius: 19px;
        background-color: #F15753;
        color:#fff;
        font-size: 32px;
        position: absolute;
        border: 0;
        outline: 0;
    }

    .inputFolderContainer button[disable=false]{
        animation:focus 0.3s ease 1 forwards
    }

    .inputFolderContainer button[disable=true]{
        opacity: 0.2 !important;
        pointer-events: none !important;
    }

    .inputFolderContainer button.inset{
        width: 250px;
        height: 76px;
        right: 40px;
    }

    .inputFolderContainer button.outset{
        width: 100%;
        position: relative !important;
        height: 76px;
        margin-top: 20px;
    }

    .inputFolderContainer span{
        position: absolute;
        left: 40px;
        height: 76px;
        width: 76px;
    }

    .inputFolderContainer span.outset{
        height: 30px !important;
        width: 30px !important;
        left: 20px !important;
        top: 35px;
    }

    .inputFolderContainer svg{
        width: 100%;
        height: 100%;
    }

    .autocompleteTags{
        overflow-y: auto;
        overflow-x: hidden;
    }

    .autocompleteTags::-webkit-scrollbar {
        background-color: transparent;
        width: 8px;
    }

    .autocompleteTags::-webkit-scrollbar-thumb {
        background-color: rgba(241, 87, 83, 0.6);
        border-radius: 7px;
    }

    .matchTag{
        height: 80px;
        font-size: 20px;
        cursor: pointer;
        color: #000;
        background-color: #f3f3f3;
        border-radius: 8px;
        border:1px solid #D5D5D5;
        overflow: hidden;
        line-height: 1.3;
    }

    .matchTag.selected{
        border:1px dashed #F15753;
        color:#f1413b;
    }

    .matchTag:hover{
        transform: scale(1.05);
        z-index: 100;
    }

    .matchTag svg{
        height: 60%;
        width: auto;
        margin-right: 10px;
        flex-shrink: 0;
    }

    .matchTag svg path{
        fill:#F15753
    }

    .matchLabel{
        max-height: 100%;
    }

    .modeSelector{
        cursor: pointer;
    }

    .modeSelector:hover label{
        color: #656565;
    }

    .modeSelector input{
        height: 48px;
        width: 48px;
        outline: none;
        border-radius: 50%;
        border:1px solid #d5d5d5;
        -webkit-appearance: none;
        position: relative;
        cursor: pointer;
        flex-shrink: 0;
    }

    .modeSelector  input:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
    }

    .modeSelector  input:checked:before {
        background: #656565;
    }

    .modeSelector label{
        font-size: 30px;
        color:#d5d5d5;
        font-weight:bolder;
        word-wrap: break-word;
    }

    @keyframes appendUp {
        0%{
            transform: scale(0);
            opacity: 0;
        }
        100%{
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes focus {
        0%{
            transform: scale(1);
        }
        50%{
            transform: scale(1.1);
        }
        100%{
            transform: scale(1);
        }
    }
</style>