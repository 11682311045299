<template lang="html">
    <div id="imagenologia" v-if="pID!=null && auth !=null && authValid != false" class="w-100 justify-content-start justify-content-lg-start d-flex align-items-start align-items-start align-content-start flex-column module" :class="empty == true ? 'no-pad' : ''">
        <div id="backdrop" v-if="modals.uploadWindow || modals.folderWindow || modals.shareWindow"></div>
        <template v-if="modals.uploadWindow">
            <upload @closeModal="closeModal" :auth="auth" :patient="pID" :doctor="dID" :folders="folders" @saveTemp="saveData" @newFile="addUpFile" @removeFile="removeUpFile" :wizard="wizard" :preData="preData" :con="con"></upload>
        </template>
        <template v-if="modals.folderWindow">
            <folder @closeModal="closeModal" @saveTemp="saveData" :wizard="wizard" :tags="tags"></folder>
        </template>
        <template v-if="modals.shareWindow">
            <shared @editDoctor="editDoctor" :auth="auth" @shareMovement="shareMovement" @saveTemp="saveData" :preData="preData" @closeModal="closeModal" :wizard="wizard" :con="con" :doctors="doctors" :patient="patient"></shared>
        </template>
        
        <div v-if="Object.keys(folders).length > 0 && empty == false" class="col-12 d-flex flex-wrap justify-content-end floatAdd">
            <button class="newResourceBtn ps-3 pe-1 d-flex flex-nowrap justify-content-between align-items-center align-content-center" @click="openModal('folder', true)">
                <div class="flex-fill d-flex" style="padding-top:1px">Crear carpeta</div> <img v-svg-inline :src="a2">
            </button>
        </div>
        <div v-else class="emptyFiles py-5 w-100 d-flex justify-content-center flex-wrap align-items-center align-content-center h-100" role="button" @click="openModal('folder', true)">
            <svg id="iconFiles" fill="#000000" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="800px" height="800px" viewBox="0 0 31.677 31.677" xml:space="preserve" class="" style="" focusable="false" role="presentation" tabindex="-1" data-src="http://localhost:8080/img/a25.c14ee048.svg">
                <g>
                    <g>
                        <path id="p1" d="M12.159,2.162c-0.714,0-1.293,0.579-1.293,1.293v1.781h2.254V5.133c0-0.453,0.367-0.819,0.819-0.819h14.765
                        c0.452,0,0.819,0.366,0.819,0.819v10.662c0,0.283-0.229,0.514-0.513,0.514v2.254h1.372c0.714,0,1.294-0.579,1.294-1.294V3.455
                        c0-0.714-0.579-1.293-1.294-1.293H12.159z"></path>
                        <path id="p2" d="M6.56,9.541h2.153c0-0.452,0.366-0.818,0.818-0.818h14.438c0.633,0,1.146,0.513,1.146,1.146v10.335
                        c0,0.283-0.229,0.513-0.512,0.513h-0.205v2.153h1.578c0.713,0,1.293-0.579,1.293-1.294V7.648c0-0.652-0.531-1.182-1.184-1.182
                        H7.853c-0.714,0-1.293,0.579-1.293,1.293V9.541z"></path>
                        <path id="p3" d="M22.963,16.819c0-1.474-1.193-2.665-2.666-2.665h-7.894v0.019l-0.794-1.74c-0.433-0.949-1.381-1.558-2.424-1.558h-6.52
                        C1.193,10.875,0,12.067,0,13.54v0.609v4.413v9.374c0,0.825,0.668,1.496,1.493,1.5l20.24,0.079c0.73,0.003,1.396-0.422,1.701-1.084
                        l1.483-3.215c0.112-0.244,0.093-0.528-0.052-0.756c-0.146-0.226-0.396-0.361-0.664-0.361h-1.238V16.819z M15.274,22.87h-2.153
                        v2.255h-2.254V22.87H8.713v-2.153h2.153v-2.123c0-0.018,0.014-0.03,0.032-0.03h2.186h0.037v2.153h2.153V22.87z"></path>
                    </g>
                </g>
            </svg>
            <div class="w-100 d-flex justify-content-center fw-bold fs-1">Añadir archivos</div>
        </div>
        <div class="folderListing col-12 mt-3 mt-md-0 d-flex flex-wrap flex-fill align-items-start align-content-start">
            <template v-for="(resource, rkey, ridx) in folders">
                <template v-for="(folder, fkey, fidx) in resource">
                    <template v-if="folder.hasOwnProperty('files')">
                        <template v-if="folder.files.length > 0">
                            <div class="resourceLine d-flex flex-wrap w-100 pe-2">

                                <div class="w-100">
                                    <span class="date-f1">{{format(folder.date, 1)}}&nbsp;</span><span class="date-f2">{{format(folder.date, 2)}}&nbsp;</span><span class="date-f3 ms-2">{{fkey}}</span>
                                </div>

                                <div class="w-100 d-flex flex-nowrap pt-3">
            
                                    <div :class="folder.shared.doctors.length  > 0 || folder.shared.patient ? 'shared active' : 'notshared'" class="sharedIndicator ms-md-4 ms-0" @click="shareFilesInFolder(fkey, rkey, folder.shared)">
                                        <img v-svg-inline :src="a4" class="a">
                                        <img v-svg-inline :src="a9" class="b">
                                    </div>

                                    <div class="sharedLabel ps-2 ps-md-3">
                                        <span v-if="folder.shared.patient && folder.shared.doctors.length > 0">
                                            Paciente y {{folder.shared.doctors.length}} más</span>
                                        </span>
                                        <span v-else-if="folder.shared.patient">
                                            compartido con el paciente</span>
                                        </span>
                                        <span v-else-if="folder.shared.doctors.length == 1" >
                                            compartido con {{folder.shared.doctors.length}} doctor</span>
                                        </span>
                                        <span v-else-if="folder.shared.doctors.length > 1" >
                                            compartido con {{folder.shared.doctors.length}} doctores</span>
                                        </span>
                                    </div>  
                                    
                                </div>
                                <div class="w-100 d-flex flex-nowrap ms-0 ps-md-4 py-4">
                                    <div class="w-auto d-flex flex-nowrap fileList">
                                        <template v-for="(file, flidx) in folder.files">
                                            <file :patient="pID" :content="file" :dated="rkey" :folder="file.folder" :idx="flidx" @delete="deleteFile" @edit="editFile"></file>
                                        </template>
                                    </div>
                                    <div class="addIcon d-flex justify-content-center align-items-center align-content-center" @click="uploadFilesToFolder(fkey, rkey)">
                                        <img v-svg-inline :src="a2">
                                    </div>
                                </div>
                            </div>

                        </template>
                    </template>
                </template>
            </template>
        </div>
    </div>
    <div v-else class="d-flex flex-wrap mt-5 justify-content-center align-items-center align-content-center">
        <div class="w-100 text-center mt-5 pt-5"><h1>Sin autorización válida o paciente vacío</h1></div>
        <img :src="a26" alt="" class="w-25 mt-2">
    </div>
</template>
<script>
//import axios from "axios";
import file from "@/components/file.vue";
import shared from "@/components/shareModal.vue";
import upload from "@/components/uploadModal.vue";
import folder from "@/components/folderModal.vue";
export default {
    name:"Images",
    props:{
        con:{
            default:{},
            type: Object
        },
    },
    components:{
        file,
        upload,
        folder,
        shared
    },
    data(){
        return{
            pID:(this.$route.query.patientID) ? this.$route.query.patientID : null ,
            dID:(this.$route.query.doctorID) ? this.$route.query.doctorID : null ,
            auth:(this.$route.query.auth) ? this.$route.query.auth : null ,
            /*con:{
                api: process.env.NODE_ENV == 'production' ? "https://noisy.efisense.xyz/" : "https://noisy.efisense.xyz/",
                headers: {
                    headers: {
                        'Authorization': 'Bearer '+((this.$route.query.auth) ? this.$route.query.auth : null),
                    },
                },
            },*/
            a2:require('@/assets/images/expedient/a2.svg'),
            a9:require('@/assets/images/expedient/a9.svg'),
            a4:require('@/assets/images/expedient/a4.svg'),
            a25:require('@/assets/images/expedient/a25.svg'),
            a26:require('@/assets/images/expedient/a26.jpg'),
            modals:{
                uploadWindow:false,
                folderWindow:false,
                shareWindow:false,
            },
            todayString:(new Date).toLocaleString("en-CA", { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric' }),
            empty:false,
            wizard:false,
            authValid:false,
            tags:{},
            folders:{},
            doctors:{},
            preData:{},
            patient:{},
        }
    },
    mounted(){

        window.addEventListener('message', (event) => {
            if(typeof event.data == 'object'){
                if(event.data.hasOwnProperty('trigger')){
                    switch(event.data.trigger){
                        case "rechargeImages":
                            this.rechargeData();
                            break;
                        case "save":
                            break;
                        case "getHeight":
                            window.parent.postMessage({ trigger: 'setHeightIframe', value: document.body.scrollHeight + 'px', id: event.data.id }, '*');
                            break;
                    }
                }
            }
        });

        if(this.pID!=null&&this.auth!=null){
                this.authValid = true;
            this.getPatientData().then(()=>{
                this.getFilesInUser();
                this.getFolderTags();
                this.getDoctors();
            }).catch((err)=>{
                console.log(err)
                this.authValid = false;
            })
            document.addEventListener('click', (e) => {
                e = e || window.event;
                let target = e.target || e.srcElement;
                if(target != null){
                    if(!target.classList.contains('edit') && !target.classList.contains('matchInfoEdit') && !target.classList.contains('nameEdit')){
                        this.removeEdit();
                    }
                }   
            }, false);
        }
    },
    methods:{

        rechargeData(){
            this.getFilesInUser();
        },

        editDoctor(id, type, value){

            let requestBody={};
            requestBody[type] = value;

            if(id != 0){

                this.con.editDoctor({
                    'id':id,
                    'data':requestBody,
                    'token':this.auth
                }).then((response)=>{
                    if(response.code == 200){
                        this.doctors[id][type] = value;
                    }
                }).catch((error) => {
                    console.log(error)
                });

                /*axios.put(this.con.api + "apiOnico/Doctor/"+id, requestBody, this.con.headers).then((response) => {
                    if(response.status == 200){
                        this.doctors[id][type] = value;
                    }
                }).catch((error) => {
                    console.log(error)
                });*/
            }else{
                requestBody.name = this.patient.name;
                requestBody.lastName = this.patient.lastname;
                if(type=='phone1' || type == "phone2"){
                    requestBody['typePhone'+(type.replace(/\D/g, ''))] = "celular";
                }

                this.con.editPatient({
                    'id':id,
                    'data':requestBody,
                    'token':this.auth
                }).then((response)=>{
                    if(response.code == 200){
                        this.patient = {...this.patient, ...requestBody};
                    }
                }).catch((error) => {
                    console.log(error)
                });

                /*axios.put(this.con.api + "apiOnico/Patient/"+this.pID, requestBody, this.con.headers).then((response) => {
                    if(response.status == 200){
                        this.patient = {...this.patient, ...requestBody};
                    }
                }).catch((error) => {
                    console.log(error)
                });*/
            }
        },

        shareFilesInFolder(folder, date, shared={patient:false,doctors:[]}){
            this.preData = {'folder':folder, 'date':date, 'shared':shared};
            this.openModal('share', false);
        },

        uploadFilesToFolder(folder, date){
            this.preData = {'folder':folder, 'date':date};
            this.openModal('upload', true);
        },

        shareMovement(data){
            if(this.folders.hasOwnProperty(this.preData.date)){
                if(this.folders[this.preData.date].hasOwnProperty(this.preData.folder)){
                    this.folders[this.preData.date][this.preData.folder].shared = data;
                }
            }
        },

        removeUpFile(file){

            if(file.folder == '' || file.folder == null){
                file.folder = file.date;
            }

            if(this.folders.hasOwnProperty(file.date)){
                if(this.folders[file.date].hasOwnProperty(file.folder)){
                    for(let i in this.folders[file.date][file.folder].files){
                        if(this.folders[file.date][file.folder].files[i].idAttachment == file.idAttachment){
                            this.folders[file.date][file.folder].files.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            this.checkEmptyness();
        },

        checkEmptyness(){
            let toEmpty = true;

            for(let dated in this.folders){
                for(let folder in this.folders[dated]){
                    if(this.folders[dated][folder].hasOwnProperty('files')){
                        if(this.folders[dated][folder].files.length > 0){
                            toEmpty = false;
                            break;
                        }
                    }

                }
                
            }

            for(let folder in this.folders[this.todayString]){
                if(this.folders[this.todayString][folder].hasOwnProperty('files')){
                    if(this.folders[this.todayString][folder].files.length == 0){
                        this.folders[this.todayString][folder].shared = {patient:false, doctors:[]};
                    }
                }
            }

            this.empty = toEmpty;

        },

        addUpFile(file){
            this.empty = false;
            let date = Object.keys(file)[0];
            let folder = file[date].files[0].folder;
            
            if(this.folders.hasOwnProperty(date)){
                if(this.folders[date].hasOwnProperty(folder)){
                    this.folders[date][folder].files.unshift(file[date].files[0]);
                    window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                    return true
                }else{
                    this.folders[date][folder] = file[date];
                    this.folders[date][folder].shared = {
                        "patient": false,
                        "doctors": []
                    };
                }
            }else{
                this.getFilesInUser();
            }
            window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
        },

        editFile(idFile, data){
            let requestBody = {
				"fileNameUser": String(data)
			};

            this.con.editFileName({token: this.auth, data:requestBody, id:idFile}).then((response) => {
				if(response.data != null && response.code == 200){
                    window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                }
			}).catch((error) => {
				console.log(error)
			})
            
            /*axios.put(this.con.api + "apiOnico/index.php/File/"+id, requestBody, this.con.headers).then((response) => {
				if(response.status == 200){
                    window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                }
			}).catch((error) => {
				console.log(error)
			})*/
        },

        deleteFile(idFile, dated, folder, idx){
            if(folder == '' || folder == null){
                folder = dated;
            }

            this.con.deleteFile({token: this.auth, id:idFile}).then((response) => {
				if(response.data != null && response.code == 200){
                    if(this.folders.hasOwnProperty(dated)){
                        if(this.folders[dated].hasOwnProperty(folder)){
                            this.folders[dated][folder].files.splice(idx, 1);
                            this.checkEmptyness();
                            this.$forceUpdate();
                            window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                            return true
                        }
                    }
                    window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                }
			}).catch((error) => {
				console.log(error)
			})

            /*axios.delete(this.con.api + "apiOnico/index.php/File/"+id, this.con.headers).then((response) => {
				if(response.data != null && response.status == 200){
                    if(this.folders.hasOwnProperty(dated)){
                        if(this.folders[dated].hasOwnProperty(folder)){
                            this.folders[dated][folder].files.splice(idx, 1);
                            this.checkEmptyness();
                            this.$forceUpdate();
                            window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                            return true
                        }
                    }
                    window.parent.postMessage({ trigger: 'refreshImagesSurgicalModule' }, '*'); // refrescar el módulo de nota prequirurgica
                }
			}).catch((error) => {
				console.log(error)
			})*/
        },

        getPatientData(){
            let requestBody = {
				"id": this.pID
			};

      
            return new Promise((resolve, reject)=>{

                this.con.getPatientData({token: this.auth, data:requestBody}).then((response) => {
                    if(response.data != null && response.code == 200){
                        this.patient = response.data[this.pID];
                        resolve(true)
                    }
                }).catch((error) => {
                    console.log(error);
                    reject(false)
                })

                /*axios.post(this.con.api + "apiOnico/index.php/Patient/index", requestBody, this.con.headers).then((response) => {
                    if(response.data != null && response.status == 200){
                        this.patient = response.data.data[this.pID];
                        resolve(true)
                    }
                }).catch((error) => {
                    console.log(error)
                    reject(false)
                });*/
            })
            
        },

        getDoctors(){
            this.con.getDoctors({token: this.auth}).then((response)=>{
                if(response.data != null && response.code == 200){
                    this.doctors = response.data;
                }
            }).catch((error) => {
                console.log(error);
            });
            /*axios.get(this.con.api + "apiOnico/Doctor", this.con.headers).then((response) => {
				if(response.data != null && response.status == 200){
                    this.doctors = response.data.data;
                }
			}).catch((error) => {
				console.log(error)
			});*/
        },

        getFolderTags(){
            this.con.getFolderTags({token: this.auth, data:null}).then((response)=>{
                if(response.data != null && response.code == 200){
                    this.tags = response.data;
                }
            }).catch((error) => {
                console.log(error);
            });

            /*axios.post(this.con.api + "apiOnico/File/listFolder", null, this.con.headers).then((response) => {
				if(response.data != null && response.status == 200){
                    this.tags = response.data.data;
                }
			}).catch((error) => {
				console.log(error)
			});*/
        },

        getFilesInUser(){

            let requestBody = {
				"idPatient": this.pID,
                "orderBy": "date",
                "groupBy": "folder"
			};

            this.con.getFilesInUser({token: this.auth, data:requestBody}).then((response) => {
                if(response.data != null && response.code == 200){
                    this.folders = {};
                    this.folders[this.todayString] = {}
                    if(response.data.length == 0){
                        this.empty = true;
                    }else{
                        this.empty = false;
                    }
                    this.folders = {...this.folders, ...response.data};
                }
            }).catch((error) => {
                console.log(error);
                reject(false)
            })

            /*axios.post(this.con.api + "apiOnico/index.php/File/index", requestBody, this.con.headers).then((response) => {
				if(response.data != null && response.status == 200){
                    this.folders = {};
                    this.folders[this.todayString] = {}
                    if(response.data.data.length == 0){
                        this.empty = true;
                    }else{
                        this.empty = false;
                    }
                    this.folders = {...this.folders, ...response.data.data};
                }
			}).catch((error) => {
				console.log(error)
			});*/
        },

        saveData(data, next=false, to=null, from='', wait=false){
            this.preData = {...this.preData, ...data}
            if(next & to!==null){
                this.closeModal(from, false);
                this.openModal(to, true);
            }
            else if(wait){
                return true
            }
            else{
                this.wizard = false;
                this.closeModal(from);
            }
        },

        openModal(name, wizard=false){
            this.wizard = wizard;
            window.parent.postMessage({ code: 'openModal'}, '*');
            switch(name){
                case 'upload':
                    this.modals.uploadWindow = true;
                    break;
                case 'folder':
                    this.modals.folderWindow = true;
                    break;
                case 'share':
                    this.modals.shareWindow = true;
                    break;
            }
        },
        closeModal(name, reset=true){
            this.wizard = false;
            if(reset){
                this.preData = {};
            }
            window.parent.postMessage({ code: 'closeModal'}, '*');
            switch(name){
                case 'upload':
                    this.modals.uploadWindow = false;
                    break;
                case 'folder':
                    this.modals.folderWindow = false;
                    break;
                case 'share':
                    this.modals.shareWindow = false;
                    break;
            }
        },
        removeEdit(){
            document.querySelectorAll('.edit').forEach((elm)=>{
                try{
                    elm.setAttribute("contenteditable", false);
                    elm.classList.remove('edit');
                }catch(err){}
            })
        },
        format(date, type){
            let datedD = date.split(' ');
            switch(type){
                case 1:
                    return datedD[0]+" "+(datedD[1]).toLowerCase();
                case 2:
                    return datedD[2];
            }
        }
    }
}
</script>
<style lang="css" scoped>
    #imagenologia{
        overflow:hidden;
    }
    .folderListing{
        height: auto;
    }
    .folderListing::-webkit-scrollbar {
        background-color: transparent;
        width: 9px;
        height: 10px;
    }
    .folderListing::-webkit-scrollbar-thumb {
        background-color: rgba(241, 87, 83, 0.6);
        border-radius: 7px;
    }
    .resourceLine:not(:first-child){
        margin-top: 2em;
    }
    .sharedIndicator{
        width: 70px;
        padding: 4px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border-radius: 20px;
        cursor: pointer;
    }
    .sharedIndicator:hover{
        background-color:rgba(241, 87, 83, 1) !important;
    }
    .sharedIndicator.active{
        background-color:#63F3FF 
    }
    .sharedIndicator:not(.active){
        background-color: #D5D5D5;
    }
    .sharedIndicator svg{
        width: 100%;
        height: 100%;
        pointer-events: none;
        object-fit: contain; 
    }
    .sharedLabel{
        font-size: 18px;
        color:#95989A
    }
    .date-f1{
        color:#343434;
        font-size: 22px;
        font-weight: bold;
    }
    .date-f2{
        color:#95989A;
        font-size: 23px;
    }
    .date-f3{
        color:#95989A;
        font-size: 20px;
    }
    .fileList{
        padding-right: 20px;
        margin-right: 10px;
        overflow-x: auto;
    }
    .fileList .file{
        margin-right: 30px;
    }
    .floatAdd{
        position: relative;
        top: 1.5rem;
        height: 40px;
    }
    .newResourceBtn{
        border: 4px solid #63F3FF;
        border-radius: 30px;
        height: 40px;
        width: 250px;
        outline: none;
        background: #fff;
        font-size: 16px;
        color:#B2B2B2;
        position: fixed;
        top: 25px;
        line-height: 0;
    }
    .newResourceBtn:hover{
        background-color: #63F3FF;
        color:#fff;
    }
    .newResourceBtn svg{
        width: 32px;
        height: 25px;
        object-fit: contain;
    }
    .newResourceBtn:hover svg path{
        fill:#fff;
    }
    .newResourceBtn svg path{
        fill:#63F3FF;
    }
    .addIcon{
        width: 140px;
        height: 140px;
        border: 3px solid #D5D5D5;
        border-radius: 40px;
        flex: 0 0 140px;
        cursor: pointer;
    }
    .addIcon svg{
        width: 50%;
        height: 50%;
        object-fit: contain; 
        pointer-events: none;
    }
    ::v-deep(.addIcon path){
        fill:#D5D5D5;
    }
    .addIcon:hover{
        transform: scale(1.05);
        opacity: 0.7;
        border: 3px solid #63F3FF;
    }
    ::v-deep(.addIcon:hover path){
        fill:#63F3FF;
    }
    .fileList::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 10px;
    }

    .fileList::-webkit-scrollbar-thumb {
        background-color: rgba(241, 87, 83, 0.6);
        border-radius: 7px;
        cursor: pointer;
    }

    .emptyFiles{
        color: #fff;
        background-color:#f96764;
        transition: background 0.3s ease;
        opacity: 0;
        animation: appendFirst 0.5s ease 1 forwards;
    }

    .emptyFiles:hover{
        color: #f96764;
        background-color:#D5D5D5;
    }

    .emptyFiles:active{
        color: #fff;
        background-color: #f96764;
    }

    .emptyFiles svg{
        margin-left: 3.1%;
        width: 200px;
        height: 200px;
    }

    #iconFiles #p1{
        transform: translate(-10px, 10px);
        transition: transform 0.5s ease, opacity 0.5s ease;
        opacity:0;
    }
    #iconFiles #p2{
        transform: translate(-5px, 6px);
        transition: transform 0.5s ease, opacity 0.5s ease;
        opacity:0;
    }

    #iconFiles{
        fill: #F8AAA8;
        overflow: visible;
    }

    .emptyFiles:hover #iconFiles{
        fill: #F15753;
    }

    .emptyFiles:active #iconFiles{
        fill: #fff;
    }

    .emptyFiles:hover #iconFiles #p1, .emptyFiles:hover #iconFiles #p2{
        transform: translate(0, 0);
        opacity: 1;
    }

    @keyframes appendFirst {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .shared svg.b{
        display: none;
    }
    .notshared svg.a{
        display: none;
    }

    #backdrop{
        position: fixed;
        left:0;
        top:0;
        width: 110vw;
        height:100vh;
        background-color:rgba(0,0,0,0.3);
        z-index: 1900;
    }

</style>