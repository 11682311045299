import axios from "axios";

class request {

	apiUrl = "";
    defToken = "";

	settings = {}

	defSettings = {
		"url": "",
		"method": "GET",
		"timeout": 0,
	}

	constructor(api) {
		this.apiUrl = api;
	}

	jsonRequest = (req, parameter = null) => {

		this.settings = {};

		switch (req) {

			case "share":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/Shared";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "unshare":
				this.settings = {...this.defSettings};
				this.settings["method"] = "DELETE";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/Shared";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getFilesInUser":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/File/index";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getFolderTags":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/File/listFolder";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = null;
				break;

			case "getDoctors":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnico/Doctor";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;

			case "getPatientData":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/Patient/index";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "editDoctor":
				this.settings = {...this.defSettings};
				this.settings["method"] = "PUT";
				this.settings["url"] = this.apiUrl + "apiOnico/Doctor/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "editPatient":
				this.settings = {...this.defSettings};
				this.settings["method"] = "PUT";
				this.settings["url"] = this.apiUrl + "apiOnico/Patient/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "getSurgicalOptions":
				this.settings = {...this.defSettings};
				this.settings["method"] = "GET";
				this.settings["url"] = this.apiUrl + "apiOnico/Conf/surgicalNote";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter
				}
				break;

			case "getSurgicalData":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/Record/index";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			
			case "getFilesInUser":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/File/index";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			
			case "postFilesInUser":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/File";
				this.settings["headers"] = { 
					'Content-Type': "multipart/form-data",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = parameter.data;
				break;

			case "getAvailabilityInSchedule":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/Appointment/availability";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "editFileName":
				this.settings = {...this.defSettings};
				this.settings["method"] = "PUT";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/File/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "deleteFile":
				this.settings = {...this.defSettings};
				this.settings["method"] = "DELETE";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/File/"+parameter.id;
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				break;

			case "blockSpaceDate":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/index.php/appointment/locked";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;

			case "saveSurgicalNote":
				this.settings = {...this.defSettings};
				this.settings["method"] = "POST";
				this.settings["url"] = this.apiUrl + "apiOnico/Record";
				this.settings["headers"] = { 
					"Content-Type": "application/json",
					"Authorization": "Bearer " + parameter.token
				}
				this.settings["data"] = JSON.stringify(parameter.data);
				break;
			

			default:
				return false
		}
		return this.settings
	}



	/* imagenologia */

	getPatientData = (data = {}) => {
		let json = this.jsonRequest("getPatientData", data);
		return this.petition(json, false)
	}

	share = (data = {}) => {
		let json = this.jsonRequest("share", data);
		return this.petition(json, false)
	}

	unshare = (data = {}) => {
		let json = this.jsonRequest("unshare", data);
		return this.petition(json, false)
	}

	getFilesInUser = (data = {}) => {
		let json = this.jsonRequest("getFilesInUser", data);
		return this.petition(json, false)
	}

	getDoctors = (data = {}) => {
		let json = this.jsonRequest("getDoctors", data);
		return this.petition(json, false)
	}

	getFolderTags = (data = {}) => {
		let json = this.jsonRequest("getFolderTags", data);
		return this.petition(json, false)
	}

	editPatient = (data = {}) => {
		let json = this.jsonRequest("editPatient", data);
		return this.petition(json, false)
	}

	editDoctor = (data = {}) => {
		let json = this.jsonRequest("editDoctor", data);
		return this.petition(json, false)
	}

	editFileName = (data = {}) => {
		let json = this.jsonRequest("editFileName", data);
		return this.petition(json, false)
	}

	deleteFile = (data = {}) => {
		let json = this.jsonRequest("deleteFile", data);
		return this.petition(json, false)
	}


	/* - END - imagenologia */

	/* nota quirurgica */

	postFilesInUser = (data = {}) => {
		let json = this.jsonRequest("postFilesInUser", data);
		return this.petition(json, false)
	}

	getSurgicalOptions = (data = {}) => {
		let json = this.jsonRequest("getSurgicalOptions", data);
		return this.petition(json, false)
	}
	getSurgicalData = (data = {}) => {
		let json = this.jsonRequest("getSurgicalData", data);
		return this.petition(json, false)
	}
	getFilesInUser = (data = {}) => {
		let json = this.jsonRequest("getFilesInUser", data);
		return this.petition(json, false)
	}
	
	getAvailabilityInSchedule = (data = {}) => {
		let json = this.jsonRequest("getAvailabilityInSchedule", data);
		return this.petition(json, false)
	}
	blockSpaceDate = (data = {}) => {
		let json = this.jsonRequest("blockSpaceDate", data);
		return this.petition(json, false)
	}
	saveSurgicalNote = (data = {}) => {
		let json = this.jsonRequest("saveSurgicalNote", data);
		return this.petition(json, false)
	}

	/* - END - nota quirurgica */





	petition = async (json, watch=true) => {
		return new Promise(function(resolve, reject) {
			if(json){
				axios(json)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					if(watch){
						console.log(error);
					}
					reject(error);
				})
			}else{
				let response = {data:false,code:500}
				resolve(response);
			}
		});
	}
}


export { request };