<template lang="html">
    <div class="coverLoader" v-if="active">
      <span class="loader"></span>
    </div>
</template>
<script>
export default {
    name:"loader",
    props:{
        active:{
            default:true,
            type: Boolean
        }
    }
}
</script>
<style lang="css">
.coverLoader{
    position:fixed;
    background-color:rgba(255, 255, 255, 0.5);
    top:0;
    left:0;
    display:flex;
    justify-content:center;
    align-content:center;
    align-items:center;
    width:100vw;
    height:100vh;
    z-index:10000;
}
.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  margin-bottom: 10vh;
}
.loader {
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader:before {
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader:after {
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em var(--white) }
  40% { box-shadow: 0 2.5em 0 0 var(--primary) }
}
</style>