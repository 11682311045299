<template lang="html">
    <div id="wrapper">
        <lineMenu></lineMenu>
        <div id="wrapperInner">
            <router-view :con="con"></router-view>
        </div>
    </div>
</template>
<script>
import lineMenu from '@/components/lineMenu.vue'
export default {
    name:"moduleWrapper",
    components:{
        lineMenu
    },
    props:{
        con:{
            default:{},
            type: Object
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="css">
    #wrapper{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: auto;
        width: 100vw;
        max-width:1920px;
        overflow-x: hidden;
        overflow-y: auto;
        display:flex;
    }
    #wrapperInner{
        width:100vw
    }
    @media (min-width: 992px){
        #wrapper{
            height: 100vh;
            margin-right: 3px;
            margin-left: 3px;
        }
        #wrapperInner{
            max-width:calc(1920px - 200px);
            width:calc(100vw - 200px)
        }
    }
</style>