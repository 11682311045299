import Vue from 'vue'
import VueRouter from 'vue-router'
import notaQuirurgica from '@/views/modules/notaQuirurgica.vue'
import Images from '../views/modules/Images.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'images',
    component: Images
  },
  {
    path: '/surgicalNote',
    name: 'notaQuirurgica',
    component: notaQuirurgica
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
